import {ReactElement, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import EditProfilePictureModal from "../modals/EditProfilePictureModal";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import SignUpToast from "../SignUpToast";

function GoVipHeaderUserDetails(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        editProfileModal,
        setEditProfileModal,
    ] = useState<boolean>(false);
    const [
        count,
        setCount,
    ] = useState<number>(0);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const {data: userProfile} = useGetUserProfile(fullToken);

    function toggleEditProfileModal() {
        if (fullToken) {
            setEditProfileModal(!editProfileModal);
        } else {
            setShowToast(true);
        }
    }

    function handleToastDismissal() {
        setShowToast(false);
    }

    function getProfileImageUrl(): string {
        if (fullToken && userProfile?.user.gravatar) {
            const versionParam = userProfile.user.profilePicture
                ? `?v=${count}`
                : "";
            return userProfile.user.gravatar + versionParam;
        }

        return `${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`;
    }

    return (
        <>
            {fullToken &&
            <EditProfilePictureModal
				    isOpen={editProfileModal}
				    onClose={toggleEditProfileModal}
				    onProfilePictureUpdate={() => setCount(count + 1)}
            />
            }
            <SignUpToast
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <div className="go-vip-top_header_user-details" onClick={toggleEditProfileModal}>
                <img
                    className="go-vip-top_header_user-details_profile_picture"
                    src={getProfileImageUrl()}
                    alt="User Profile"
                />
                {fullToken && userProfile?.user.nftProfilePicture &&
                    <NftProfilePictureBadge size="xl" className="go-vip-top_header_user-details_nft-icon"/>
                }
            </div>
        </>
    );
}

export default GoVipHeaderUserDetails;
