/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWClaimLog,
    OWClaimLogFromJSON,
    OWClaimLogFromJSONTyped,
    OWClaimLogToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the user\'s reward stats/progress.
 * @export
 * @interface GuildQuestUserStat
 */
export interface GuildQuestUserStat {
    /**
     * The ID of the user for which this reward is applicable.
     * @type {string}
     * @memberof GuildQuestUserStat
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GuildQuestUserStat
     */
    gameId?: string;
    /**
     * Reference to the OWGames document if it exists.
     * @type {string}
     * @memberof GuildQuestUserStat
     */
    game?: string;
    /**
     * Whether the quest has been completed.
     * @type {boolean}
     * @memberof GuildQuestUserStat
     */
    isCompleted: boolean;
    /**
     * Whether the reward has been claimed.
     * @type {boolean}
     * @memberof GuildQuestUserStat
     */
    isClaimed: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuildQuestUserStat
     */
    quest: string;
    /**
     * The progress towards the target.
     * @type {number}
     * @memberof GuildQuestUserStat
     */
    progress: number;
    /**
     * The target to reach to claim the reward.
     * @type {number}
     * @memberof GuildQuestUserStat
     */
    target: number;
    /**
     * The UNIX timestamp of the start of the week (Mon 12AM EST)
     * @type {number}
     * @memberof GuildQuestUserStat
     */
    weekStart?: number;
    /**
     * The progress towards the target for the current week.
     * @type {number}
     * @memberof GuildQuestUserStat
     */
    weeklyProgress?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GuildQuestUserStat
     */
    isWeeklyLimitFeedbackDismissed?: boolean;
    /**
     * 
     * @type {Array<OWClaimLog>}
     * @memberof GuildQuestUserStat
     */
    claimLogs: Array<OWClaimLog>;
    /**
     * 
     * @type {string}
     * @memberof GuildQuestUserStat
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GuildQuestUserStat
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof GuildQuestUserStat
     */
    updatedAt: number;
}

export function GuildQuestUserStatFromJSON(json: any): GuildQuestUserStat {
    return GuildQuestUserStatFromJSONTyped(json, false);
}

export function GuildQuestUserStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildQuestUserStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'gameId': !exists(json, 'gameId') ? undefined : json['gameId'],
        'game': !exists(json, 'game') ? undefined : json['game'],
        'isCompleted': json['isCompleted'],
        'isClaimed': json['isClaimed'],
        'quest': json['quest'],
        'progress': json['progress'],
        'target': json['target'],
        'weekStart': !exists(json, 'weekStart') ? undefined : json['weekStart'],
        'weeklyProgress': !exists(json, 'weeklyProgress') ? undefined : json['weeklyProgress'],
        'isWeeklyLimitFeedbackDismissed': !exists(json, 'isWeeklyLimitFeedbackDismissed') ? undefined : json['isWeeklyLimitFeedbackDismissed'],
        'claimLogs': ((json['claimLogs'] as Array<any>).map(OWClaimLogFromJSON)),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function GuildQuestUserStatToJSON(value?: GuildQuestUserStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'gameId': value.gameId,
        'game': value.game,
        'isCompleted': value.isCompleted,
        'isClaimed': value.isClaimed,
        'quest': value.quest,
        'progress': value.progress,
        'target': value.target,
        'weekStart': value.weekStart,
        'weeklyProgress': value.weeklyProgress,
        'isWeeklyLimitFeedbackDismissed': value.isWeeklyLimitFeedbackDismissed,
        'claimLogs': ((value.claimLogs as Array<any>).map(OWClaimLogToJSON)),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


