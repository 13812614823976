import useThemePreference from "@/hooks/useThemePreference";
import { ReactNode } from "react";

interface StepperButtonProps {
    children: ReactNode;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
}

export const StepperButton = ({ children, onClick, disabled }: StepperButtonProps) => {
    const {isOnDarkMode} = useThemePreference();
    const color = isOnDarkMode ? "#E6E6E6" : "#08080A";
    const backgroundColor = isOnDarkMode ? "#39393B" : "white";
    const disabledStyle = {
        backgroundColor: isOnDarkMode ? "#212123" : "#E0E0E1",
        color: isOnDarkMode ? "#39393B" : "#87878D",
    };
    const conditionalStyles = {
        color,
        backgroundColor,
        ...disabled ? disabledStyle : {},
    };

    return (
        <button disabled={disabled} style={conditionalStyles} onClick={onClick} className="menu-item-customization-form_group_quantity-stepper_circle-button">
            {children}
        </button>
    );
};