import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

function RestaurantSearchAddressButtonSkeleton(): ReactElement {
    return (
        <div className='restaurant_search_address_button_skeleton'>
            <Skeleton width={200} height={48}/>
        </div>
    );
}

export default RestaurantSearchAddressButtonSkeleton;