import {ReactElement, useEffect, useState} from "react";
import {Address, AddressBooksApi} from "@devour/client";
import FrameOneAddressAutocomplete from "../../../components/inputs/FrameOneAddressAutocomplete";
import {AiOutlineSearch} from "react-icons/ai";

interface Props {
    autocompleteCallback: (newAddress: Address) => void;
    placeholder?: string;
}

function RestaurantAddressAutoPanelAutocomplete(props: Props): ReactElement {
    const [
        autocompleteOperational,
        setAutocompleteOperational,
    ] = useState<boolean>(true);

    useEffect(() => {
        void getAutocompleteStatus();
    }, []);

    /**
	 * Check if autocomplete API is enabled. It could be disabled if we exceed our Google places API quota.
	 */
    async function getAutocompleteStatus() {
        try {
            const res = await new AddressBooksApi().autocompleteStatus();
            setAutocompleteOperational(res.autoCompleteOperational);
        } catch {
            setAutocompleteOperational(false);
        }
    }

    if (!autocompleteOperational) {
        return null;
    }

    return (
        <div className="restaurant-search-address-bottom-panel_body_autocomplete">
            <AiOutlineSearch className="icon-search"/>
            <FrameOneAddressAutocomplete
                placeholder={props.placeholder}
                callback={props.autocompleteCallback}
                onError={() => setAutocompleteOperational(false)}
            />
        </div>
    );

}

export default RestaurantAddressAutoPanelAutocomplete;
