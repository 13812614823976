import {ButtonHTMLAttributes, ReactElement} from "react";
import {Guild, GuildRewardType, GuildsApi} from "@devour/client";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import {
    addError,
    decrementLoading,
    incrementLoading,
} from "@/redux/meta/metaActions";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    guild: Guild;
}

function GuildMintBadgeButton(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: guildProgress, refetch: refetchGuildProgress } = useGetGuildProgress(props.guild?.id, fullToken);
    const isEveryQualifierQuestComplete = guildProgress?.quests
        .filter(q => q.rewardType === GuildRewardType.GUILDQUALIFIER)
        .every(q => q.hasUserCompletedQuest);

    async function mintGuildBadge(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new GuildsApi(getConfig()).mintGuildBadge({
                id: props.guild?.id,
            });
            await refetchGuildProgress();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    if (guildProgress?.hasUserMintedBadge) {
        return null;
    }

    return (
        <div className="guild-mint-badge">
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="large"
                onClick={mintGuildBadge}
                forwardProps={{
                    disabled: !isEveryQualifierQuestComplete,
                }}
                showSpinner={true}
            >
								Mint Guild Badge
            </FrameButton>
        </div>
    );
}

export default GuildMintBadgeButton;
