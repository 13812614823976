/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains games that has Overwolf game events support
 * @export
 * @interface OWGame
 */
export interface OWGame {
    /**
     * The Overwolf ID of the game
     * @type {string}
     * @memberof OWGame
     */
    gameId: string;
    /**
     * The title of the game
     * @type {string}
     * @memberof OWGame
     */
    name: string;
    /**
     * The description of the game.
     * @type {string}
     * @memberof OWGame
     */
    description: string;
    /**
     * 
     * @type {Array<OWGameEventType>}
     * @memberof OWGame
     */
    events: Array<OWGameEventType>;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    devourGameLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    bannerImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    bannerImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    landingImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    landingImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    thumbnailImage?: string;
    /**
     * The image to show in the time reward breakdown
     * @type {string}
     * @memberof OWGame
     */
    timeRewardImage?: string;
    /**
     * The flag to check if the game has event support by Overwolf
     * @type {boolean}
     * @memberof OWGame
     */
    isEventSupported: boolean;
    /**
     * The flag to check if the game is supported by DevourGO
     * @type {boolean}
     * @memberof OWGame
     */
    isEventSupportReady?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OWGame
     */
    hasUserStats?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OWGame
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OWGame
     */
    updatedAt: number;
}

export function OWGameFromJSON(json: any): OWGame {
    return OWGameFromJSONTyped(json, false);
}

export function OWGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWGame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'name': json['name'],
        'description': json['description'],
        'events': ((json['events'] as Array<any>).map(OWGameEventTypeFromJSON)),
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'devourGameLogo': !exists(json, 'devourGameLogo') ? undefined : json['devourGameLogo'],
        'bannerImageSmall': !exists(json, 'bannerImageSmall') ? undefined : json['bannerImageSmall'],
        'bannerImageLarge': !exists(json, 'bannerImageLarge') ? undefined : json['bannerImageLarge'],
        'landingImageSmall': !exists(json, 'landingImageSmall') ? undefined : json['landingImageSmall'],
        'landingImageLarge': !exists(json, 'landingImageLarge') ? undefined : json['landingImageLarge'],
        'thumbnailImage': !exists(json, 'thumbnailImage') ? undefined : json['thumbnailImage'],
        'timeRewardImage': !exists(json, 'timeRewardImage') ? undefined : json['timeRewardImage'],
        'isEventSupported': json['isEventSupported'],
        'isEventSupportReady': !exists(json, 'isEventSupportReady') ? undefined : json['isEventSupportReady'],
        'hasUserStats': !exists(json, 'hasUserStats') ? undefined : json['hasUserStats'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function OWGameToJSON(value?: OWGame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameId': value.gameId,
        'name': value.name,
        'description': value.description,
        'events': ((value.events as Array<any>).map(OWGameEventTypeToJSON)),
        'logo': value.logo,
        'icon': value.icon,
        'devourGameLogo': value.devourGameLogo,
        'bannerImageSmall': value.bannerImageSmall,
        'bannerImageLarge': value.bannerImageLarge,
        'landingImageSmall': value.landingImageSmall,
        'landingImageLarge': value.landingImageLarge,
        'thumbnailImage': value.thumbnailImage,
        'timeRewardImage': value.timeRewardImage,
        'isEventSupported': value.isEventSupported,
        'isEventSupportReady': value.isEventSupportReady,
        'hasUserStats': value.hasUserStats,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


