import {MenuOrderItem, MenuOrderSubItem} from "@devour/client";

export function getUniqueIDForMenuOrderItem(item: MenuOrderItem): string {
    const id = item.menuItemId || item.externalId;
    const customizationNames = item.customizations?.sort((a, b) => b.name.localeCompare(a.name))
        .map(c => getCustomizationIdRecursive(c))
        .join("_") || "";
    return [id, customizationNames].filter(Boolean).join("_");
}

function getCustomizationIdRecursive(item: MenuOrderSubItem): string {
    const id = item.menuCustomizationItemId || item.externalId;
    const childIds = item.customizations?.sort((a, b) => b.name.localeCompare(a.name))
        .map(c => getCustomizationIdRecursive(c))
        .reduce((acc, curr) => acc + "_" + curr, "") || "";
    return [id, childIds].filter(Boolean).join("_");
}
