import { MenuOrder } from "@devour/client";
import { FC, useState } from "react";
import OrderHistoryDetailsOrderItem from "../OrderHistoryDetailsOrderItem";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { toggleChatOpen } from "@/redux/meta/metaActions";
import OrderReceiptModal from "../modals/OrderReceiptModal";
import FrameButton from "../buttons/FrameButton";
import useThemePreference from "@/hooks/useThemePreference";
import CancelMenuOrder from "./CancelMenuOrder";
import { erroredList } from "@/pages/menu-orders/MenuOrderListPage";

interface props {
    order: MenuOrder;
}

const MenuOrderSuccessSummary: FC<props> = ({ order }) => {
    const dispatch = useDispatch();
    const hasError = erroredList.includes(order.status);
    const { isOnDarkMode } = useThemePreference();
    const isChatOpen = useSelector((store: IStore) => store.metaStore.isChatOpen);
    const [showReceiptModal, setShowReceiptModal] = useState(false);

    /**
     * Show or hide the receipt modal.
     *
     */
    function toggleReceiptModal(): void {
        setShowReceiptModal((s) => !s);
    }

    /**
     * Show or hide the help modal.
     *
     */
    function toggleHelpModal(): void {
        dispatch(toggleChatOpen(!isChatOpen));
    }
    return (
        <>
            <OrderReceiptModal isOpen={showReceiptModal} order={order} onClose={toggleReceiptModal} />
            <div className="order-success-page_summary">
                <h4>Order Summary</h4>
                <hr />
                {order.orderItems.map((item, index) =>
                    <OrderHistoryDetailsOrderItem key={index} item={item} />)}

                <div className="order-success-page_summary_action-buttons">
                    <FrameButton color="purple-outline" size="large" onClick={toggleReceiptModal}>
                            View Receipt
                    </FrameButton>
                    {window.Atlas && !hasError &&
                        <FrameButton
                            color={isOnDarkMode ? "purple" : "purple-text-no-border"}
                            size="large"
                            onClick={toggleHelpModal}
                        >
                            Get Help
                        </FrameButton>
                    }
                    <CancelMenuOrder menuOrder={order} />
                </div>
            </div>
        </>
    );
};

export default MenuOrderSuccessSummary;
