import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {isMobile} from "react-device-detect";
import ProgressBar from "@ramonak/react-progress-bar";
import {ReactElement, useEffect, useState} from "react";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {updateAccountLevel} from "@/redux/meta/metaActions";
import {IoChevronForward} from "react-icons/io5";
import GoVipLevelsModal from "@/components/modals/GoVipLevelsModal.tsx";
import {FiChevronRight} from "react-icons/fi";

export default function OverwolfGoVipAccountLevel() {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const [showGoVipLevelsModal, setShowGoVipLevelsModal] = useState<boolean>(false);
    const {data: userProfile} = useGetUserProfile(fullToken);
    const {data: userLevelData} = useGetUserLevel(fullToken, currentUser?.user?.id);

    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const userLevel = accountLevel?.level ?? 1;
    const experienceGained = fullToken && accountLevel ? accountLevel.experienceGainedAtThisLevel : 0;
    const totalLevelExperience = fullToken && accountLevel ? accountLevel.experienceGainedAtThisLevel + accountLevel.experienceRequiredForNextLevel : 0;

    useEffect(() => {
        if (userLevelData) {
            dispatch(updateAccountLevel(userLevelData));
        }
    }, [userLevelData]);


    function toggleGoVipPrizesModal(): void {
        setShowGoVipLevelsModal(!showGoVipLevelsModal);
    }

    return (
        <div className="overwolf-govip_xp">
            <GoVipLevelsModal
                isOpen={showGoVipLevelsModal}
                toggle={toggleGoVipPrizesModal}
            />
            <div className="overwolf-govip_xp_profile-pic">
                {fullToken
                    ? <>
                        {userProfile?.user?.gravatar &&
                            <img
                                src={userProfile.user.gravatar}
                                alt="User Profile"
                            />
                        }
                    </>
                    : <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/devourgo-profile.webp`}
                        alt="User Profile"
                    />
                }
            </div>

            <div className="overwolf-govip_xp_content">
                <span className="go-vip-top_header_user-details_level_container">
                    Level {fullToken && userProfile ? userProfile.user?.level : 0}
                </span>
                <div className="go-vip-top_header_exp_counter-container">
                    <div className="go-vip-top_header_exp_counter-container_left">
                        <span className="go-vip-top_header_exp_counter-container_counter">
                            {fullToken ? accountLevel?.experienceGainedAtThisLevel : 0}
                        </span>
                        <span className="go-vip-top_header_exp_counter-container_points">
                            XP
                        </span>
                    </div>
                    <div>
                        <button
                            className="go-vip-top_header_rank-button reset-button"
                            onClick={toggleGoVipPrizesModal}
                        >
                            <strong>Level Rewards</strong>
                            <FiChevronRight/>
                        </button>
                        <div className="go-vip-top_header_exp_counter-container_exp">
                            {experienceGained.toLocaleString()} / {totalLevelExperience.toLocaleString()}
                        </div>
                    </div>
                </div>

                <div className="overwolf-govip_xp_bar">
                    <ProgressBar
                        height="15px"
                        bgColor={"linear-gradient(255deg, #DED6F8 34.51%, #AA96EF 70.22%)"}
                        baseBgColor={"#212123"}
                        className="overwolf-govip_xp_bar_bar"
                        isLabelVisible={false}
                        completed={fullToken && accountLevel
                            ? accountLevel.experienceGainedAtThisLevel
                            : 0}
                        maxCompleted={fullToken && accountLevel
                            ? accountLevel.experienceGainedAtThisLevel +
                            accountLevel.experienceRequiredForNextLevel
                            : 0}
                    />
                </div>
            </div>
        </div>
    );
}