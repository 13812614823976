import { useSelector } from "react-redux";
import useGetOverwolfGameInfo from "./useGetOverwolfGameInfo";
import { IStore } from "@/redux/defaultStore";
import { isMobile } from "react-device-detect";
import {useAsset} from "@/hooks/useAsset";

export enum OverwolfArtType {
    logo = "logo",
    devourLogo = "devourLogo",
    bannerImage = "bannerImage",
    landingImage = "landingImage",
    thumbnailImage = "thumbnailImage",
}


const defaultAssets = {
    logo: `${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`,
    devourLogo: `${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`,
    bannerImage: `${import.meta.env.VITE_CDN_URL}/images/ow-time-govip-bg${isMobile ? "-sm" : ""}.webp`,
    landingImage: `${import.meta.env.VITE_CDN_URL}/images/Time-Based-Game-Landing-${isMobile ? "Sm" : "Lg"}.webp`,
    thumbnailImage: "",
};

export default function useGetOverwolfArt(artType: OverwolfArtType) {
    const owGameInfo = useSelector((store: IStore) => store.metaStore.overwolfGameInfo);
    const {
        data: overwolfGameInfo,
    } = useGetOverwolfGameInfo(owGameInfo?.gameId);
    const hasCustomLogo = !!overwolfGameInfo?.logo;
    const getDefaultArt = () => {

        if (!overwolfGameInfo?.gameId) {
            if (defaultAssets[artType]) {
                return defaultAssets[artType];
            }
            return "";
        }

        switch (artType) {
            case OverwolfArtType.bannerImage:
                return defaultAssets.bannerImage;
            case OverwolfArtType.landingImage:
                return defaultAssets.landingImage;
            case OverwolfArtType.logo:
                return defaultAssets.logo;
            case OverwolfArtType.devourLogo:
                return defaultAssets.devourLogo;
            case OverwolfArtType.thumbnailImage:
                return defaultAssets.thumbnailImage;
            default:
                return "";
        }

    };
    const getOverwolfArtId = () => {

        if (!overwolfGameInfo?.gameId) {
            return "";
        }

        switch (artType) {
            case OverwolfArtType.bannerImage:
                return overwolfGameInfo?.[isMobile ? "bannerImageSmall" : "bannerImageLarge"];
            case OverwolfArtType.landingImage:
                return overwolfGameInfo?.[isMobile ? "landingImageSmall" : "landingImageLarge"];
            case OverwolfArtType.logo:
                return overwolfGameInfo?.logo;
            case OverwolfArtType.devourLogo:
                return overwolfGameInfo?.devourGameLogo;
            case OverwolfArtType.thumbnailImage:
                return overwolfGameInfo?.thumbnailImage;
            default:
                return "";
        }

    };
    const {data: asset} = useAsset(getOverwolfArtId());

    return {
        getOverwolfArt: asset?.url || getDefaultArt(),
        hasCustomLogo,
    };
}
