/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GuildRewardMilestone
 */
export interface GuildRewardMilestone {
    /**
     * 
     * @type {string}
     * @memberof GuildRewardMilestone
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GuildRewardMilestone
     */
    target: number;
    /**
     * 
     * @type {number}
     * @memberof GuildRewardMilestone
     */
    amount: number;
}

export function GuildRewardMilestoneFromJSON(json: any): GuildRewardMilestone {
    return GuildRewardMilestoneFromJSONTyped(json, false);
}

export function GuildRewardMilestoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildRewardMilestone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'target': json['target'],
        'amount': json['amount'],
    };
}

export function GuildRewardMilestoneToJSON(value?: GuildRewardMilestone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'target': value.target,
        'amount': value.amount,
    };
}


