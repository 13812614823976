/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserSummaryResponse
 */
export interface GetUserSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUserSummaryResponse
     */
    totalXp?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserSummaryResponse
     */
    totalTime?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserSummaryResponse
     */
    totalGames?: number;
}

export function GetUserSummaryResponseFromJSON(json: any): GetUserSummaryResponse {
    return GetUserSummaryResponseFromJSONTyped(json, false);
}

export function GetUserSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalXp': !exists(json, 'totalXp') ? undefined : json['totalXp'],
        'totalTime': !exists(json, 'totalTime') ? undefined : json['totalTime'],
        'totalGames': !exists(json, 'totalGames') ? undefined : json['totalGames'],
    };
}

export function GetUserSummaryResponseToJSON(value?: GetUserSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalXp': value.totalXp,
        'totalTime': value.totalTime,
        'totalGames': value.totalGames,
    };
}


