import {ReactElement, useEffect, useState} from "react";
import classNames from "classnames";
import {FiX} from "react-icons/fi";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";

interface DiscoverWaysToEarnXPStepMetadata {
    title: string;
    description: string;
    image: string;
}

const DiscoverWaysToEarnXPSteps: Array<DiscoverWaysToEarnXPStepMetadata> = [
    {
        title: "Playing Games Every Hour",
        description: "Earn XP every hour you play our supported games",
        image: `${import.meta.env.VITE_CDN_URL}/images/5ways-earn-xp-step1.webp`,
    },
    {
        title: "Completing Game Quests",
        description: "Play games with special quests and claim XP",
        image: `${import.meta.env.VITE_CDN_URL}/images/5ways-earn-xp-step2.webp`,
    },
    {
        title: "Ordering Food",
        description: "Place your meals and earn free XP",
        image: `${import.meta.env.VITE_CDN_URL}/images/5ways-earn-xp-step3.webp`,
    },
    {
        title: "Spin the Wheel",
        description: "Spin the wheel to earn up to 150 XP daily",
        image: `${import.meta.env.VITE_CDN_URL}/images/5ways-earn-xp-step4.webp`,
    },
    {
        title: "Join Our Discord",
        description: "Engage with the community and earn XP",
        image: `${import.meta.env.VITE_CDN_URL}/images/5ways-earn-xp-step5.webp`,
    },
];

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function OWDiscoverEarnXPModal(props: Props) {
    const [currentStep, setCurrentStep] = useState<number>(0);

    useEffect(() => {
        if (props.isOpen) {
            setCurrentStep(0);
        }
    }, [props.isOpen]);

    function renderStep(): ReactElement {
        const step = DiscoverWaysToEarnXPSteps[currentStep];
        const isLastStep = currentStep === DiscoverWaysToEarnXPSteps.length - 1;
        const isFirstStep = currentStep === 0;

        return (
            <div className="ow-discover-earn-xp-modal_container">
                <div className="ow-discover-earn-xp-modal_text">
                    <div className="ow-discover-earn-xp-modal_text_title">
                        {step.title}
                    </div>
                    <div className="ow-discover-earn-xp-modal_text_caption">
                        {step.description}
                    </div>
                </div>

                <div
                    className="ow-discover-earn-xp-modal_img"
                    style={{backgroundImage: `url(${step.image})`}}
                />

                <div className="ow-discover-earn-xp-modal_stepper">
                    <FaAngleLeft
                        className={classNames("left", {
                            hide: isFirstStep,
                            arrow: !isFirstStep,
                            white: isLastStep,
                        })}
                        size={"2rem"}
                        onClick={
                            () => {
                                if (!isFirstStep) {
                                    setCurrentStep(currentStep - 1);
                                }
                            }
                        }
                    />

                    <div className="ow-discover-earn-xp-modal_stepper_steps">
                        {DiscoverWaysToEarnXPSteps.map((step, index) =>
                            <div
                                key={step.title}
                                className={classNames("ow-discover-earn-xp-modal_stepper_step", {
                                    active: currentStep === index,
                                })}
                                onClick={() => setCurrentStep(index + 1)}
                            />)}
                    </div>
                    <FaAngleRight
                        className={classNames("", {
                            hide: isLastStep,
                            arrow: !isLastStep,
                        })}
                        size={"2rem"}
                        onClick={
                            () => {
                                if (!isLastStep) {
                                    setCurrentStep(currentStep + 1);
                                }
                            }
                        }
                    />
                </div>
            </div>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="ow-discover-earn-xp-modal"
            size="xs1"
        >
            <div
                className="ow-discover-earn-xp-modal_body"
            >
                <div className="ow-discover-earn-xp-modal_nav">
                    <div className="ow-discover-earn-xp-modal_tag">5 Ways to Earn XP</div>

                    <FiX
                        size="1.75rem"
                        strokeWidth="0.1875rem"
                        onClick={props.onClose}
                        className="close"
                    />
                </div>
                {renderStep()}
            </div>
        </FrameOneModal>
    );
}
