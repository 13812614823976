/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    NftType,
    NftTypeFromJSON,
    NftTypeFromJSONTyped,
    NftTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Used to save an Industry collection purchase attempt.
 * @export
 * @interface NftPurchase
 */
export interface NftPurchase {
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    user: string;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    gasFee: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    stripeTotal: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    dpay: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchase
     */
    fiatInDpay: number;
    /**
     * 
     * @type {boolean}
     * @memberof NftPurchase
     */
    isComplete: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftPurchase
     */
    isPending: boolean;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    dpayTransaction?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    dpayTransactionRefund?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    paymentIntentId?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    paymentIntentRefundId?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    contract?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NftPurchase
     */
    airdrop?: boolean;
    /**
     * 
     * @type {object}
     * @memberof NftPurchase
     */
    error?: object;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    walletAddress: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    receipt?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    tokenId?: string;
    /**
     * 
     * @type {string}
     * @memberof NftPurchase
     */
    coinbaseChargeId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NftPurchase
     */
    isCoinbase?: boolean;
    /**
     * 
     * @type {NftType}
     * @memberof NftPurchase
     */
    type?: NftType;
}

export function NftPurchaseFromJSON(json: any): NftPurchase {
    return NftPurchaseFromJSONTyped(json, false);
}

export function NftPurchaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftPurchase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'price': json['price'],
        'gasFee': json['gasFee'],
        'quantity': json['quantity'],
        'total': json['total'],
        'stripeTotal': json['stripeTotal'],
        'dpay': json['dpay'],
        'fiatInDpay': json['fiatInDpay'],
        'isComplete': json['isComplete'],
        'isPending': json['isPending'],
        'dpayTransaction': !exists(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'dpayTransactionRefund': !exists(json, 'dpayTransactionRefund') ? undefined : json['dpayTransactionRefund'],
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'paymentIntentId': !exists(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'paymentIntentRefundId': !exists(json, 'paymentIntentRefundId') ? undefined : json['paymentIntentRefundId'],
        'contract': !exists(json, 'contract') ? undefined : json['contract'],
        'airdrop': !exists(json, 'airdrop') ? undefined : json['airdrop'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'walletAddress': json['walletAddress'],
        'receipt': !exists(json, 'receipt') ? undefined : json['receipt'],
        'tokenId': !exists(json, 'tokenId') ? undefined : json['tokenId'],
        'coinbaseChargeId': !exists(json, 'coinbaseChargeId') ? undefined : json['coinbaseChargeId'],
        'isCoinbase': !exists(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'type': !exists(json, 'type') ? undefined : NftTypeFromJSON(json['type']),
    };
}

export function NftPurchaseToJSON(value?: NftPurchase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'price': value.price,
        'gasFee': value.gasFee,
        'quantity': value.quantity,
        'total': value.total,
        'stripeTotal': value.stripeTotal,
        'dpay': value.dpay,
        'fiatInDpay': value.fiatInDpay,
        'isComplete': value.isComplete,
        'isPending': value.isPending,
        'dpayTransaction': value.dpayTransaction,
        'dpayTransactionRefund': value.dpayTransactionRefund,
        'paymentMethodId': value.paymentMethodId,
        'paymentIntentId': value.paymentIntentId,
        'paymentIntentRefundId': value.paymentIntentRefundId,
        'contract': value.contract,
        'airdrop': value.airdrop,
        'error': value.error,
        'walletAddress': value.walletAddress,
        'receipt': value.receipt,
        'tokenId': value.tokenId,
        'coinbaseChargeId': value.coinbaseChargeId,
        'isCoinbase': value.isCoinbase,
        'type': NftTypeToJSON(value.type),
    };
}


