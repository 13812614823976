/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    GuildQuestUserStat,
    GuildQuestUserStatFromJSON,
    GuildQuestUserStatFromJSONTyped,
    GuildQuestUserStatToJSON,
    GuildRewardMilestone,
    GuildRewardMilestoneFromJSON,
    GuildRewardMilestoneFromJSONTyped,
    GuildRewardMilestoneToJSON,
    GuildRewardType,
    GuildRewardTypeFromJSON,
    GuildRewardTypeFromJSONTyped,
    GuildRewardTypeToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the quests that can be completed.
 * @export
 * @interface GuildQuest
 */
export interface GuildQuest {
    /**
     * 
     * @type {string}
     * @memberof GuildQuest
     */
    guild: string;
    /**
     * 
     * @type {string}
     * @memberof GuildQuest
     */
    game: string;
    /**
     * The title of the quest.
     * @type {string}
     * @memberof GuildQuest
     */
    name: string;
    /**
     * The description of the quest.
     * @type {string}
     * @memberof GuildQuest
     */
    description?: string;
    /**
     * 
     * @type {GuildRewardType}
     * @memberof GuildQuest
     */
    rewardType: GuildRewardType;
    /**
     * The amount of reward that can be claimed.
     * @type {number}
     * @memberof GuildQuest
     */
    rewardAmount: number;
    /**
     * The maximum amount of reward that can be claimed.
     * @type {number}
     * @memberof GuildQuest
     */
    rewardMax?: number;
    /**
     * The metadata of the reward.
     * @type {object}
     * @memberof GuildQuest
     */
    rewardMetaData?: object;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof GuildQuest
     */
    rewardCategory: OWLogCategory;
    /**
     * 
     * @type {OWGameEventType}
     * @memberof GuildQuest
     */
    eventType: OWGameEventType;
    /**
     * The target amount of progress to reach to claim the reward.
     * @type {number}
     * @memberof GuildQuest
     */
    target: number;
    /**
     * 
     * @type {Array<GuildRewardMilestone>}
     * @memberof GuildQuest
     */
    milestones?: Array<GuildRewardMilestone>;
    /**
     * 
     * @type {boolean}
     * @memberof GuildQuest
     */
    isActive?: boolean;
    /**
     * 
     * @type {GuildQuestUserStat}
     * @memberof GuildQuest
     */
    userStat?: GuildQuestUserStat;
    /**
     * 
     * @type {string}
     * @memberof GuildQuest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GuildQuest
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof GuildQuest
     */
    updatedAt: number;
}

export function GuildQuestFromJSON(json: any): GuildQuest {
    return GuildQuestFromJSONTyped(json, false);
}

export function GuildQuestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildQuest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guild': json['guild'],
        'game': json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rewardType': GuildRewardTypeFromJSON(json['rewardType']),
        'rewardAmount': json['rewardAmount'],
        'rewardMax': !exists(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMetaData': !exists(json, 'rewardMetaData') ? undefined : json['rewardMetaData'],
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'eventType': OWGameEventTypeFromJSON(json['eventType']),
        'target': json['target'],
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(GuildRewardMilestoneFromJSON)),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'userStat': !exists(json, 'userStat') ? undefined : GuildQuestUserStatFromJSON(json['userStat']),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function GuildQuestToJSON(value?: GuildQuest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guild': value.guild,
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'rewardType': GuildRewardTypeToJSON(value.rewardType),
        'rewardAmount': value.rewardAmount,
        'rewardMax': value.rewardMax,
        'rewardMetaData': value.rewardMetaData,
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'eventType': OWGameEventTypeToJSON(value.eventType),
        'target': value.target,
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(GuildRewardMilestoneToJSON)),
        'isActive': value.isActive,
        'userStat': GuildQuestUserStatToJSON(value.userStat),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


