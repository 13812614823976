import {ReactElement} from "react";
import classNames from "classnames";
import {OWGame} from "@devour/client";
import {useGetGuilds} from "@/hooks/useGetGuilds";
import GuildCard from "@/components/guilds/GuildCard";
import GuildQualifierCard from "@/pages/guilds/GuildQualifierCard";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    show: boolean;
    toggle: () => void;
    game?: OWGame;
}

function GuildsInfoDrawer(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: guildsResponse} = useGetGuilds(undefined, undefined, undefined, [props.game?.id]);
    const { data: guildProgress } = useGetGuildProgress(guildsResponse?.guilds?.[0]?.id, fullToken);

    return (
        <>
            <div
                className={classNames("gofrens-community-info-drawer-overlay", {
                    "is-active": props.show,
                })}
                onClick={props.toggle}
            />
            <div
                className={classNames("gofrens-community-info-drawer", {
                    "is-active": props.show,
                })}
            >
                <div className="gofrens-community-info-drawer_contents">
                    <div className="gofrens-community-info-drawer_contents_main">
                        {guildsResponse?.guilds?.map((guild) =>
                            <GuildCard
                                key={guild.id}
                                guild={guild}
                            />)}
                    </div>
                    {guildProgress && <GuildQualifierCard
                        quests={guildProgress.quests}
                        guild={guildProgress.guild}
                    />}
                </div>
                <div className="gofrens-community-info-drawer_footer">
                    <button
                        onClick={props.toggle}
                        className="close-btn"
                    >
												Close
                    </button>
                </div>
            </div>
        </>
    );
}

export default GuildsInfoDrawer;
