/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOverwolfGamesResponseAllOf,
    GetOverwolfGamesResponseAllOfFromJSON,
    GetOverwolfGamesResponseAllOfFromJSONTyped,
    GetOverwolfGamesResponseAllOfToJSON,
    OWGame,
    OWGameFromJSON,
    OWGameFromJSONTyped,
    OWGameToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOverwolfGamesResponse
 */
export interface GetOverwolfGamesResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<OWGame>}
     * @memberof GetOverwolfGamesResponse
     */
    games: Array<OWGame>;
}

export function GetOverwolfGamesResponseFromJSON(json: any): GetOverwolfGamesResponse {
    return GetOverwolfGamesResponseFromJSONTyped(json, false);
}

export function GetOverwolfGamesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOverwolfGamesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'games': ((json['games'] as Array<any>).map(OWGameFromJSON)),
    };
}

export function GetOverwolfGamesResponseToJSON(value?: GetOverwolfGamesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'games': ((value.games as Array<any>).map(OWGameToJSON)),
    };
}


