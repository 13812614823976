import FrameButton from "@/components/buttons/FrameButton";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import {useOnLogin} from "@/hooks/useOnLogin";
import {store} from "@/redux";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router";
import useGetOverwolfArt, {OverwolfArtType} from "@/hooks/useGetOverwolfArt";
import OverwolfGameLogo from "./components/OverwolfGameLogo";
import OwIntroductionModal from "@/components/devourPlay/OWIntroductionModal";


const OverwolfLandingPage = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isHotkeyModalOpen, setIsHotkeyModalOpen] = useState(true);
    const {getOverwolfArt} = useGetOverwolfArt(OverwolfArtType.landingImage);
    const navigate = useNavigate();

    useEffect(() => {
        if (store.getState().authStore.fullToken) {
            navigate("/overwolf-go-vip");
        }
    }, []);

    useOnLogin(() => {
        navigate("/overwolf-go-vip");
    });
    const getBackground = () => {
        return `url(${getOverwolfArt})`;
    };

    const renderTitleBreak = () => {
        return isMobile ? <br/> : <>{" "}</>;
    };

    return (
        <div className="overwolf-landing-page" style={{
            backgroundImage: getBackground(),
        }}>
            <OwIntroductionModal isOpen={isHotkeyModalOpen} onClose={() => setIsHotkeyModalOpen(false)}/>
            <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}/>

            <div className="overwolf-landing-page_content">
                <div className="overwolf-landing-page_content_logo">
                    <OverwolfGameLogo/>
                </div>
                <div className="overwolf-landing-page_content_info">
                    <span
                        className="overwolf-landing-page_content_info_title">EAT.{renderTitleBreak()}PLAY.{renderTitleBreak()}EARN.</span>
                    <p className="overwolf-landing-page_content_info_description">
                        {isMobile ? <>Order Food, Play Games, and Earn <br /> XP to Unlock Real-World Rewards!</>
                            : <>Order Food, Play Games, and Earn XP to Unlock Real-World Rewards!</>}
                    </p>
                </div>

                <div className="overwolf-landing-page_content_actions">
                    <FrameButton size="narrow" color="purple-blue-gradient-shadow"
                        onClick={() => setIsLoginModalOpen(true)}>Login or Sign up</FrameButton>
                    <FrameButton size="narrow" color="white-outline"
                        onClick={() => navigate("/overwolf-discover")}>Discover</FrameButton>
                </div>
            </div>

        </div>
    );
};

export default OverwolfLandingPage;
