import {ReactElement, useState} from "react";
import {isDesktop} from "react-device-detect";
import {FaArrowRight} from "react-icons/fa";
import useWindowSize, {getScreenWidthStr, WindowBreakpointSizes} from "@/hooks/useWindowSize";
import FrameButton from "@/components/buttons/FrameButton";
import {GetOverwolfXpSubjectEnum} from "@devour/client";
import GoVipOWTimeBasedRewardsModal from "@/components/goVip/GoVipOWTimeBasedRewardsModal";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import Tooltip from "@/components/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import OWDiscoverPlayAndEarnModal from "@/components/devourPlay/OWDiscoverPlayAndEarnModal";

interface Props {
    isOWDiscoverPage?: boolean;
}

const getHeaderContent = (isOWDiscoverPage: boolean) => {
    // Content for Discover Page
    if (isOWDiscoverPage) {
        return {
            title:
                <>
                    <div className="overwolf-discover-page_supported-games">Discover Our 2000+ Supported Games
                        <Tooltip
                            className="overwolf-govip_rewards_header_tooltip"
                            outsideIcon={BsFillQuestionCircleFill}
                            containerElementClassname="overwolf-govip_rewards"
                        >
                            <div className="overwolf-govip_rewards_header_tooltip_content">
                                Check out our supported games here: <a href="https://www.overwolf.com/supported-games" target="_blank" rel="noreferrer">overwolf.com/supported-games</a>
                            </div>
                        </Tooltip>
                    </div>
                </>,
            description: "Complete quests as you rack up game time, plus earn 10 XP for every hour played",
        };
    }

    // Default content for goVIP page
    return {
        title:
            <>
                Online Games to Boost XP
                <span>2,257</span>
            </>,
        description: `Earn 10 XP for every hour of gameplay!${isDesktop ? " The more you play, the more XP you collect" : ""}`,
    };
};

export default function GoVipOWTimeBasedGames(props: Props): ReactElement {
    const windowSize = useWindowSize();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    let windowWidth = getScreenWidthStr(windowSize[0]);
    windowWidth = windowWidth === WindowBreakpointSizes.XSMALL ? WindowBreakpointSizes.SMALL : windowWidth;
    const [showRewardsModal, setShowRewardsModal] = useState(false);
    const {data: rewardsData} = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.TIMEREWARD, undefined);
    const headerContent = getHeaderContent(!!props.isOWDiscoverPage);
    const [showPlayAndEarnModal, setShowPlayAndEarnModal] = useState<boolean>(false);

    function toggleRewardsModal() {
        setShowRewardsModal((prev) => !prev);
    }

    return (
        <div className="go-vip-overwolf_rewards">
            <GoVipOWTimeBasedRewardsModal
                isOpen={showRewardsModal}
                onClose={toggleRewardsModal}
                rewards={rewardsData?.transactions ?? []}
            />
            <OWDiscoverPlayAndEarnModal
                isOpen={showPlayAndEarnModal}
                onClose={() => setShowPlayAndEarnModal(false)}
            />
            <div className="go-vip-overwolf_rewards_header">
                <div className="go-vip-overwolf_rewards_header_text">
                    <h3 className="go-vip-overwolf_heading">
                        {headerContent.title}
                    </h3>

                    <div className="go-vip-overwolf_rewards_header_description">
                        {headerContent.description}
                    </div>
                </div>

                <a
                    className="go-vip-overwolf_rewards_header_games-link"
                    href="https://www.overwolf.com/supported-games/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {!props.isOWDiscoverPage &&
                        <>
                            {"View supported games"} <FaArrowRight size="22.3px"/>
                        </>
                    }
                </a>
            </div>

            <div
                style={{
                    backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/go-vip-ow-time-based-bnr-${windowWidth}.webp)`,
                }}
                className="go-vip-overwolf_rewards_time-based"
            >
                {
                    props.isOWDiscoverPage ? <FrameButton
                        color="devourplay-gradient"
                        size="normal"
                        className="go-vip-overwolf_rewards_time-based_btn"
                        onClick={() => setShowPlayAndEarnModal(true)}
                    >
                            How to Play and Earn
                    </FrameButton>
                        : <FrameButton
                            size="normal"
                            className="go-vip-overwolf_rewards_time-based_btn"
                            color="purple"
                            onClick={() => setShowRewardsModal(true)}
                        >
                            {rewardsData?.transactions.length > 0 ? "Track Rewards" : "Play to Earn"}
                        </FrameButton>
                }
            </div>
        </div>
    );
}
