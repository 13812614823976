import { ReactElement, useState } from "react";
import { Address, HandoffOptions, MenuOrder } from "@devour/client";
import OrderHistoryDetailsItemDisplay, {
    Props as OrderHistoryAddressDisplayProps,
} from "./OrderHistoryDetailsItemDisplay";
import OrderTrackDialog from "./OrderTrackDialog";
import { BiNote, BiTime } from "react-icons/bi";
import { LiaCarSideSolid } from "react-icons/lia";
import { MdLocationPin } from "react-icons/md";
import { createScheduleString } from "@/utils/CheckoutDetailsScheduleTime/createScheduleString";
import { isMobile, isTablet } from "react-device-detect";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useMenuOrderTracker } from "@/hooks/menuOrder/useMenuOrderTracker";
import { OrderTrackerDataFullfillmentStatus } from "@/types/OrderTrackerData";
import { useRestaurant } from "@/hooks/useRestaurant";
import { IoRestaurantOutline } from "react-icons/io5";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface Props {
    order: MenuOrder;
}

function OrderHistoryDetails(props: Props): ReactElement {
    const [showOrderTrackDialog, setShowOrderTrackDialog] = useState(false);
    const [searchParams] = useSearchParams();
    const { data: restaurant } = useRestaurant(props.order.business);
    const { hideCharacters } = useStreamerMode();
    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();
    const { data: trackerData } = useMenuOrderTracker(
        props.order.deliveryId,
        searchParams.get("debug")
            ? {
                order: props.order,
                restaurant: restaurant,
                status: searchParams.get("debug") as OrderTrackerDataFullfillmentStatus,
            }
            : undefined,
    );
    function scheduledTime(): string {
        if (props.order.scheduledTime) {
            const scheduleString = createScheduleString(
                props.order.scheduledTime,
                restaurantTimeZone,
                isMobile && !isTablet,
            );

            if (props.order.handoff === HandoffOptions.DELIVERY) {
                return `Scheduled at ${scheduleString}`;
            }

            return `Ready at  ${scheduleString}`;
        }
        return `${restaurant?.prepTime} - ${restaurant?.prepTime + 10} min`;
    }

    /**
     * Generate the text label for this section based on the order handoff type.
     *
     * @param handoff
     */
    function getLabel(handoff: HandoffOptions): string {
        switch (handoff) {
            case HandoffOptions.DELIVERY:
                return "Delivery Details";
            case HandoffOptions.PICKUP:
                return "Pick Up Details";
        }
    }
    /**
     * Format address with the components that are present.
     *
     * @param address
     */
    function formatAddressString(address: Address): string {
        let addString = "";
        if (address.line1) {
            addString += address.line1;
        }

        if (address.line2) {
            addString += ` - ${address.line2}`;
        }

        if (address.locality) {
            addString += `, ${address.locality}`;
        }

        if (address.administrativeArea) {
            addString += `, ${address.administrativeArea}`;
        }

        if (address.postalCode) {
            addString += `, ${address.postalCode}`;
        }

        return addString;
    }

    function formatPhoneNumber(phoneNumber: string) {
        try {
            const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
            return parsedPhoneNumber.formatInternational();
        } catch (e) {
            return phoneNumber;
        }
    }

    function getAddressDetails(handoff: HandoffOptions): OrderHistoryAddressDisplayProps {
        switch (handoff) {
            case HandoffOptions.DELIVERY:
                return {
                    icon: MdLocationPin,
                    title: "Deliver to",
                    description: hideCharacters(formatAddressString(props.order.address)),
                };
            case HandoffOptions.PICKUP:
                return {
                    icon: IoRestaurantOutline,
                    title: "Pickup from",
                    description: restaurant.address?.placeId ? <a
                        href={`https://www.google.com/maps/place/?q=place_id:${restaurant.address?.placeId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {formatAddressString(restaurant?.address)}
                    </a> : formatAddressString(restaurant?.address),
                };
        }
    }
    if (!props.order) {
        return null;
    }

    return (
        <>
            <OrderTrackDialog
                isOpen={showOrderTrackDialog}
                onClose={() => setShowOrderTrackDialog(false)}
                order={props.order}
            />
            <div className="order-history-details">
                <h4> {getLabel(props.order.handoff)}</h4>
                <hr />
                <div className="order-history-details_details">
                    <div className="order-history-details_details_address">
                        <OrderHistoryDetailsItemDisplay {...getAddressDetails(props.order.handoff)} />

                        {props.order.deliveryNotes &&
                            <OrderHistoryDetailsItemDisplay
                                icon={BiNote}
                                title="Instruction"
                                description={props.order.deliveryNotes}
                            />
                        }

                        <OrderHistoryDetailsItemDisplay
                            icon={BiTime}
                            title={`Standard ${
                                props.order.handoff === HandoffOptions.DELIVERY ? "delivery" : "pickup"
                            } time`}
                            description={scheduledTime()}
                        />

                        {trackerData?.runner &&
                            <OrderHistoryDetailsItemDisplay
                                icon={LiaCarSideSolid}
                                title={"Delivered by"}
                                description={
                                    <>
                                        {`${trackerData.runner.name} |`}{" "}
                                        <a href={`tel:${trackerData.runner.phone}`}>
                                            { hideCharacters(formatPhoneNumber(trackerData.runner.phone))}
                                        </a>
                                    </>
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderHistoryDetails;
