import {FC, ReactElement} from "react";
import PizzaCafe from "../games/PizzaCafe";
import CandyBlast from "../games/CandyBlast";
import StackTheBurger from "../games/StackTheBurger";
import RallyChampion from "../games/RallyChampion";
import InTheWeeds from "../games/InTheWeeds";

export interface GoVipLevelsCardData {
    level: number;
    prize: string;
    description: string;
}

export interface GoVipGameData {
    game: string;
    description: string;
    levelsData: Array<string>;
    thumbnailUrl?: string;
    reward: string;
    rewardSubtitle: string;
}

export interface GoVipGameThumbnailData {
    requiredLevel: number;
    gameContainer: FC;
    thumbnailUrl?: string;
    title: string;
    levelDetailsModal?: ReactElement;
    description: string;
    gameData?: GoVipGameData;

}

export const pizzaCafeLevelDetails: GoVipGameData = {
    game: "Pizza Cafe",
    thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/pizza-cafe/media/graphics/promo/screenshots/1.jpg`,
    description: "Come step behind the pizza counter! Can you keep your guests happy?",
    reward: "+100",
    rewardSubtitle: "XP",
    levelsData: [
        "Complete Level 1",
        "Complete Level 2",
        "Complete Level 3",
        "Complete Level 4",
        "Complete Level 5",
        "Complete Level 6",
        "Complete Level 7",
        "Complete Level 8",
        "Complete Level 9",
        "Complete Level 10",
        "Complete Level 11",
        "Complete Level 12",
    ],
};

export const candyBlastLevelDetails: GoVipGameData = {
    game: "Candy Blast",
    thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/candy-blast/media/graphics/promo/screenshots/3.png`,
    description: "Unlock the sweet rewards of GoVIP XP by matching candy by color.",
    reward: "+35",
    rewardSubtitle: "XP",
    levelsData: [
        "Complete Level 1",
        "Complete Level 2",
        "Complete Level 3",
        "Complete Level 4",
        "Complete Level 5",
        "Complete Level 6",
        "Complete Level 7",
        "Complete Level 8",
        "Complete Level 9",
        "Complete Level 10",
        "Complete Level 11",
        "Complete Level 12",
        "Complete Level 13",
        "Complete Level 14",
        "Complete Level 15",
        "Complete Level 16",
        "Complete Level 17",
        "Complete Level 18",
        "Complete Level 19",
        "Complete Level 20",
        "Complete Level 21",
        "Complete Level 22",
        "Complete Level 23",
        "Complete Level 24",
        "Complete Level 25",
        "Complete Level 26",
        "Complete Level 27",
        "Complete Level 28",
        "Complete Level 29",
        "Complete Level 30",
    ],
};

export const stackTheBurgerLevelDetails: GoVipGameData = {
    game: "Stack the Burger",
    thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/stack-the-burger-v2/media/graphics/promo/screenshots/screenshot6.jpg`,
    description: "Can you get the order right? You'll find that this one's harder than it looks!",
    reward: "+125",
    rewardSubtitle: "XP",
    levelsData: [
        "Earn $200 in game",
        "Earn $400 in game",
        "Earn $600 in game",
        "Earn $800 in game",
        "Earn $1000 in game",
        "Earn $1200 in game",
        "Earn $1400 in game",
        "Earn $1600 in game",
        "Earn $1800 in game",
        "Earn $2000 in game",
    ],
};

export const rallyChampionLevelDetails: GoVipGameData = {
    game: "Rally Champion",
    thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/rally-champion/media/graphics/promo/screenshots/4.jpg`,
    description: "10 races, 10 1st place prizes. Wish your delivery driver moved like this?",
    reward: "+150",
    rewardSubtitle: "XP",
    levelsData: ["Win 1st Place"],
};

export const inTheWeedsLevelDetails: GoVipGameData = {
    game: "In the Weeds",
    thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/in-the-weeds/media/graphics/promo/screenshots/6.png`,
    description: "When the kitchen gets crazy, it's an absolute fight. Defend your ground!",
    reward: "+★",
    rewardSubtitle: "XP",
    levelsData: [
        "Complete Level 1",
        "Complete Level 2",
        "Complete Level 3",
        "Complete Level 4",
        "Complete Level 5",
        "Complete Level 6",
        "Complete Level 7",
        "Complete Level 8",
        "Complete Level 9",
        "Complete Level 10",
        "Complete Level 11",
        "Complete Level 12",
        "Complete Level 13",
        "Complete Level 14",
        "Complete Level 15",
        "Complete Level 16",
        "Complete Level 17",
        "Complete Level 18",
    ],
};

export const GameThumbnailData: Array<GoVipGameThumbnailData> = [
    {
        requiredLevel: 1,
        gameContainer: PizzaCafe,
        thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/pizza-cafe/media/graphics/promo/screenshots/1.jpg`,
        title: "Pizza Cafe",
        description: "Come step behind the pizza counter! Can you keep your guests happy?",
        gameData: pizzaCafeLevelDetails,
    },
    {
        requiredLevel: 1,
        gameContainer: CandyBlast,
        thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/candy-blast/media/graphics/promo/screenshots/3.png`,
        title: "Candy Blast",
        description: "Unlock the sweet rewards of GoVIP XP by matching candy by color.",
        gameData: candyBlastLevelDetails,
    },
    {
        requiredLevel: 4,
        gameContainer: StackTheBurger,
        thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/stack-the-burger-v2/media/graphics/promo/screenshots/screenshot6.jpg`,
        title: "Stack the Burger",
        description: "Can you get the order right? You'll find that this one's harder than it looks!",
        gameData: stackTheBurgerLevelDetails,
    },
    {
        requiredLevel: 7,
        gameContainer: RallyChampion,
        thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/rally-champion/media/graphics/promo/screenshots/4.jpg`,
        title: "Rally Champion",
        description: "10 races, 10 1st place prizes. Wish your delivery driver moved like this?",
        gameData: rallyChampionLevelDetails,
    },
    {
        requiredLevel: 9,
        gameContainer: InTheWeeds,
        thumbnailUrl: `${import.meta.env.VITE_CDN_URL}/games/in-the-weeds/media/graphics/promo/screenshots/6.png`,
        title: "In the Weeds",
        description: "When the kitchen gets crazy, it's an absolute fight. Defend your ground!",
        gameData: inTheWeedsLevelDetails,
    },
];
