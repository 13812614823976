/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Guild,
    GuildFromJSON,
    GuildFromJSONTyped,
    GuildToJSON,
    GuildQuest,
    GuildQuestFromJSON,
    GuildQuestFromJSONTyped,
    GuildQuestToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetGuildProgressResponse
 */
export interface GetGuildProgressResponse {
    /**
     * 
     * @type {Guild}
     * @memberof GetGuildProgressResponse
     */
    guild: Guild;
    /**
     * 
     * @type {boolean}
     * @memberof GetGuildProgressResponse
     */
    isUserInGuild: boolean;
    /**
     * Has user minted the NFT before?
     * @type {boolean}
     * @memberof GetGuildProgressResponse
     */
    hasUserMintedBadge: boolean;
    /**
     * Does the user currently have the badge? They may have traded the NFT into or out of their wallet.
     * @type {boolean}
     * @memberof GetGuildProgressResponse
     */
    doesUserHaveBadge: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetGuildProgressResponse
     */
    members: Array<string>;
    /**
     * 
     * @type {Array<GuildQuest>}
     * @memberof GetGuildProgressResponse
     */
    quests: Array<GuildQuest>;
}

export function GetGuildProgressResponseFromJSON(json: any): GetGuildProgressResponse {
    return GetGuildProgressResponseFromJSONTyped(json, false);
}

export function GetGuildProgressResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGuildProgressResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guild': GuildFromJSON(json['guild']),
        'isUserInGuild': json['isUserInGuild'],
        'hasUserMintedBadge': json['hasUserMintedBadge'],
        'doesUserHaveBadge': json['doesUserHaveBadge'],
        'members': json['members'],
        'quests': ((json['quests'] as Array<any>).map(GuildQuestFromJSON)),
    };
}

export function GetGuildProgressResponseToJSON(value?: GetGuildProgressResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guild': GuildToJSON(value.guild),
        'isUserInGuild': value.isUserInGuild,
        'hasUserMintedBadge': value.hasUserMintedBadge,
        'doesUserHaveBadge': value.doesUserHaveBadge,
        'members': value.members,
        'quests': ((value.quests as Array<any>).map(GuildQuestToJSON)),
    };
}


