/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a guild to keep track of DevourPlay games.
 * @export
 * @interface Guild
 */
export interface Guild {
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Guild
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Guild
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Guild
     */
    isActive: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Guild
     */
    games: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    nftGrouping: string;
    /**
     * 
     * @type {number}
     * @memberof Guild
     */
    totalPoints: number;
    /**
     * 
     * @type {number}
     * @memberof Guild
     */
    monthlyPoints: number;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    autoMintContract?: string;
    /**
     * 
     * @type {EVMChain}
     * @memberof Guild
     */
    autoMintChain?: EVMChain;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    hero?: string;
    /**
     * 
     * @type {string}
     * @memberof Guild
     */
    icon?: string;
}

export function GuildFromJSON(json: any): Guild {
    return GuildFromJSONTyped(json, false);
}

export function GuildFromJSONTyped(json: any, ignoreDiscriminator: boolean): Guild {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': json['isActive'],
        'games': json['games'],
        'nftGrouping': json['nftGrouping'],
        'totalPoints': json['totalPoints'],
        'monthlyPoints': json['monthlyPoints'],
        'autoMintContract': !exists(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'autoMintChain': !exists(json, 'autoMintChain') ? undefined : EVMChainFromJSON(json['autoMintChain']),
        'hero': !exists(json, 'hero') ? undefined : json['hero'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function GuildToJSON(value?: Guild | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'games': value.games,
        'nftGrouping': value.nftGrouping,
        'totalPoints': value.totalPoints,
        'monthlyPoints': value.monthlyPoints,
        'autoMintContract': value.autoMintContract,
        'autoMintChain': EVMChainToJSON(value.autoMintChain),
        'hero': value.hero,
        'icon': value.icon,
    };
}


