/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OWEventTypeBody
 */
export interface OWEventTypeBody {
    /**
     * The name of the event type (e.g., \"KILL\", \"DEATH\")
     * @type {string}
     * @memberof OWEventTypeBody
     */
    name: string;
    /**
     * The structure/schema of the event data
     * @type {object}
     * @memberof OWEventTypeBody
     */
    dataStructure: object;
    /**
     * Whether the event data must match the dataStructure exactly or partially
     * @type {boolean}
     * @memberof OWEventTypeBody
     */
    partialMatch: boolean;
}

export function OWEventTypeBodyFromJSON(json: any): OWEventTypeBody {
    return OWEventTypeBodyFromJSONTyped(json, false);
}

export function OWEventTypeBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWEventTypeBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'dataStructure': json['dataStructure'],
        'partialMatch': json['partialMatch'],
    };
}

export function OWEventTypeBodyToJSON(value?: OWEventTypeBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dataStructure': value.dataStructure,
        'partialMatch': value.partialMatch,
    };
}


