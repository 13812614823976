import classNames from "classnames";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleDevourplayFeatureModal} from "@/redux/meta/metaActions";
import {IStore} from "@/redux/defaultStore";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import {useNavigate} from "react-router";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    version: string;
    title: string;
    link: string;
    description: string;
    image?: string;
    video?: string;
    mediaHeight?: string;
    size: "xs1" | "xs2";
}

export default function OWAnnouncementModal(props: Props) {
    const [isDoNotShowAgain, setIsDoNotShowAgain] = useState(false);
    const dispatch = useDispatch();
    const isDevourplayFeatureModalDismissed = useSelector((store: IStore) => store.metaStore.isDevourplayFeatureModalDismissed);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (isDoNotShowAgain) {
            dispatch(toggleDevourplayFeatureModal(true));
        }
        navigate(props.link);
        props.onClose();
    };

    const handleCheckboxToggle = () => {
        setIsDoNotShowAgain(!isDoNotShowAgain);
    };

    if (isDevourplayFeatureModalDismissed) {
        return null;
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={classNames("go-vip-ow_rewards-modal")}
            size={props.size}
        >
            <button className="reset-button overwolf-hotkey-modal_close">
                <FiX
                    size={"1.5rem"}
                    strokeWidth="0.175rem"
                    onClick={props.onClose}
                />
            </button>

            <div
                className="ow-announcement-modal"
            >
                <div className="ow-announcement-modal_header">
                    <span className="ow-announcement-modal_header_version">
                    v{props.version} New Feature Update
                    </span>

                    <div className="ow-announcement-modal_header_title">
                        {props.title}
                    </div>
                </div>

                <div className="ow-announcement-modal_img"
                    style={{
                        backgroundImage: props.image && !props.video ? `url(${props.image})` : "none",
                        position: "relative",
                        height: props.mediaHeight && props.mediaHeight,
                    }}>
                    {props.video && <video
                        className="ow-announcement-modal_img_video"
                        autoPlay
                        muted
                        playsInline
                        loop
                    >
                        <source src={`${props.video}`}
                            type="video/mp4"/>
                    </video>}
                </div>

                <div className="ow-announcement-modal_description">
                    {props.description}
                </div>

                <div className="ow-announcement-modal_button">
                    <FrameOneCheckbox
                        onToggle={handleCheckboxToggle}
                        checked={isDoNotShowAgain}
                        background="purple"
                    >
                        Do not show this again
                    </FrameOneCheckbox>

                    <FrameButton
                        size="normal"
                        className="overwolf-hotkey-modal_btn"
                        color="purple"
                        onClick={handleButtonClick}
                    >
                        Let's go!
                    </FrameButton>
                </div>
            </div>
        </FrameOneModal>
    );
}
