import { MenuCustomization, MenuOrderSubItem } from "@devour/client";

/**
 * Recursively loop through nested customizations and get all default selected items.
 * @param customizations
 */
export default function getNestedDefaultCustomizationItems(customizations: Array<MenuCustomization>): Array<MenuOrderSubItem> {
    if (!customizations?.length) {
        return [];
    }

    return customizations.flatMap((customization) => {
        return customization.options
            .filter((option) => option.defaultQuantity)
            .map((option) => {
                return {
                    menuCustomizationItemId: option.id,
                    quantity: option.defaultQuantity,
                    price: option.price, // Price property is required but will be overwritten by backend
                    taxRate: 0, // Tax rate property is required but will be overwritten by backend
                    name: "", // Name property is required but will be overwritten by backend
                    customizations: getNestedDefaultCustomizationItems(option.customizations),
                };
            });
    });
}
