import {ReactElement} from "react";
import {NftPurchaseUpsert} from "@devour/client";
import {FaWallet} from "react-icons/fa";
import {isDesktop, isTablet} from "react-device-detect";
import {truncateMiddle} from "@/utils/truncateMiddle";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleMagicWalletsModal} from "@/redux/meta/metaActions";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface Props {
    nftPurchase: NftPurchaseUpsert;
}

function MintIndustryPassModalWallet(props: Props): ReactElement {

    const isModalShown = useSelector((store: IStore) => store.metaStore.isMagicWalletsModalActive);
    const dispatch = useDispatch();
    const { hideCharacters } = useStreamerMode();

    function useMagicWallet(): void {
        dispatch(toggleMagicWalletsModal(!isModalShown));
    }

    if (!props.nftPurchase?.walletAddress) {
        return null;
    }

    return (
        <div className="mint-industry-credit-card_body_wallet">
            <div className="mint-industry-credit-card_body_wallet_mint">
                <FaWallet className="mint-industry-credit-card_body_wallet_icon"/>
                <p><strong>Mint to:</strong></p>
            </div>
            <div
                className="mint-industry-credit-card_body_wallet_button"
                onClick={useMagicWallet}
            >
                <p>{isDesktop || isTablet
                    ? hideCharacters(props.nftPurchase.walletAddress)
                    : truncateMiddle(props.nftPurchase.walletAddress, 30)}</p>
            </div>
        </div>
    );
}

export default MintIndustryPassModalWallet;
