/**
 * This component is used to display the address auto panel on the restaurant page, when logged-in
 * user doesn't have a default saved address or last searched page and access the page from Favorite.
 * @constructor
 */
import {useEffect, useState} from "react";
import {Address, AddressBook, ErrorType, HandoffOptions, Restaurant, UtilsApi} from "@devour/client";
import ManageAddressBookUpdateAddressModal from "@/components/modals/ManageAddressBookUpdateAddressModal";
import {FaLocationDot} from "react-icons/fa6";
import RestaurantAddressAutoPanelAutocomplete
    from "@/pages/restaurants/components/RestaurantAddressAutoPanelAutocomplete";
import RestaurantAddressAutoPanelGeolocate
    from "@/pages/restaurants/components/RestaurantAddressAutoPanelGeolocate";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError, updateLastSearchedPlaceId} from "@/redux/meta/metaActions";
import {useNavigate} from "react-router-dom";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    restaurant: Restaurant;
}
export default function RestaurantPageRequiredAddressAutoPanel(props: Props) {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const selectedHandoff = useSelector((store: IStore) => store.metaStore.handoff);
    const history = useNavigate();
    const [updateFormModal, setUpdateFormModal] = useState<boolean>(false);
    const [selectedAddressBook, setSelectedAddressBook] = useState<AddressBook>();
    const { isOnOverwolf } = useOverwolfInterop();

    useEffect(() => {
        if (!fullToken && props.isOpen) {
            props.onClose();
        }
    }, [fullToken?.id, props.isOpen]);

    function autocompleteCallback(newAddress: Address) {
        setSelectedAddressBook({
            ...newAddress,
            isDefault: false,
            id: undefined,
            handoffInstructions: undefined,
            user: undefined,
        });
        setUpdateFormModal(true);
    }

    async function onAddressSubmit(newAddress: Address): Promise<void> {
        if (selectedHandoff === HandoffOptions.DELIVERY && !newAddress.line1.trim()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "This address is not valid as a delivery destination.",
            }));
            return;
        }

        let placeId: string = newAddress?.placeId;
        if (!placeId) {
            const res = await new UtilsApi().getPlaceIdFromAddress({
                address: newAddress,
            });
            placeId = res.placeId;
        }

        dispatch(updateLastSearchedPlaceId(placeId));
        props.onClose();
        history(`${props.restaurant.url}/${placeId}`); // navigate to restaurant page with placeId
    }

    async function onSaveAddressBook(address: AddressBook): Promise<void> {
        setUpdateFormModal(false);
        await onAddressSubmit(address);
    }

    return (
        <>
            <ManageAddressBookUpdateAddressModal
                isOpen={updateFormModal}
                onClose={() => setUpdateFormModal(false)}
                onSave={onSaveAddressBook}
                addressBook={selectedAddressBook}
                setAsDefault={true}
            />

            <FrameOneModal
                size="sm"
                toggle={props.onClose}
                isOpen={props.isOpen}
                contentClassName="restaurant-page-required-address-modal"
                disableOverlayDismiss={true}
            >
                <FrameModalBody className="menu-order-contact-info-modal_body restaurant-page-required-address-modal_body">
                    <div className="restaurant-page-required-address-modal_body_top">
                        <FaLocationDot />
                        <span className="menu-order-contact-info-modal_header_title">
                            Add Delivery Address
                        </span>
                    </div>
                    <p>Please search your delivery address to proceed and save to your address book.</p>
                    <div className="restaurant-page-required-address-modal_body_divider"/>
                    <div>
                        <RestaurantAddressAutoPanelAutocomplete
                            placeholder="Search address"
                            autocompleteCallback={autocompleteCallback}
                        />
                        {!isOnOverwolf && <RestaurantAddressAutoPanelGeolocate
                            submitAddressCallback={autocompleteCallback}
                            setAsDefault={true}
                        />}
                    </div>
                </FrameModalBody>
            </FrameOneModal>
        </>
    );
}