import React, {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import Spacer from "@/components/Spacer";
import FrameButton from "@/components/buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import {GoFrensApi, LeaderboardsDateRange, TopCommunityLeader, TopLeaderboardCommunity} from "@devour/client";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {IStore} from "@/redux/defaultStore";
import GoFrensLeaderboardRow, {LeaderboardRowColor} from "@/components/goFrens/GoFrensLeaderboardRow";
import {isDesktop, isMobile} from "react-device-detect";
import GoFrensTopLeaderboardEmptyState from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyState";
import GoFrensTopLeaderboardSkeleton from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardSkeleton";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";
import GoFrensTopLeaderboardCommunitiesModal
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunitiesModal";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";
import GoFrensTopLeaderboardCommunityLeadersEmptyState
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityLeadersEmptyState";
import {getDateInEst} from "@/utils/getDateInEst";
import {FaRankingStar} from "react-icons/fa6";
import useThemePreference from "@/hooks/useThemePreference";

export enum LeaderboardView {
    COMMUNITIES,
    LEADERS,
}

export enum ViewMode {
    MONTHLY,
    CUMULATIVE,
}

function GoFrensTopLeaderboard(): ReactElement {
    const now = getDateInEst();
    const currMonth = now.getMonth() + 1;
    const currYear = now.getFullYear();
    const { isOnDarkMode } = useThemePreference();

    const [
        leaderboardData,
        setLeaderboardData,
    ] = useState<Array<TopLeaderboardCommunity>>(undefined);
    const [
        leadersData,
        setLeadersData,
    ] = useState<Array<TopCommunityLeader>>(undefined);

    const [
        dateRange,
        setDateRange,
    ] = useState<Array<LeaderboardsDateRange>>(undefined);

    const [
        leaderboardView,
        setLeaderboardView,
    ] = useState<LeaderboardView>(LeaderboardView.COMMUNITIES);

    // View mode for monthly or all-time points
    const [
        viewMode,
        setViewMode,
    ] = useState<ViewMode>(ViewMode.MONTHLY);

    const [
        showCommunityModal,
        setShowCommunityModal,
    ] = useState<boolean>(false);
    const [
        dateValue,
        setDateValue,
    ] = useState<string>(`${currMonth},${currYear}`);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: currentUser} = useGetUserProfile(fullToken);

    const MAX_PER_TABLE = 5;

    // Get leaderboard data
    useEffect(() => {
        void getDateRange();

        if (leaderboardView === LeaderboardView.COMMUNITIES) {
            void getLeaderboardData(`${dateValue},${viewMode}`);
        } else {
            void getCommunityLeadersData(`${dateValue},${viewMode}`);
        }
    }, [
        leaderboardView,
        viewMode,
        dateValue,
    ]);

    // Function returns view mode button content
    function viewModeButtonContent() {
        // If mobile return shorter button content
        if (isMobile) {
            return viewMode === ViewMode.MONTHLY
                ? "Monthly"
                : "All-Time";
        } else {
            return viewMode === ViewMode.MONTHLY
                ? "Monthly Leaderboard"
                : "All-Time Totals";
        }
    }

    function toggleView(): void {
        if (leaderboardView === LeaderboardView.COMMUNITIES) {
            setLeaderboardView(LeaderboardView.LEADERS);
        } else {
            setLeaderboardView(LeaderboardView.COMMUNITIES);
        }
    }

    function toggleViewMode(): void {
        viewMode === ViewMode.MONTHLY
            ? setViewMode(ViewMode.CUMULATIVE)
            : setViewMode(ViewMode.MONTHLY);
    }

    function toggleModal(): void {
        setShowCommunityModal(!showCommunityModal);
    }

    async function getDateRange(): Promise<void> {
        try {
            const res = await new GoFrensApi(getConfig()).getLeaderboardsDateRange();
            setDateRange(res.dates);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function getLeaderboardData(leaderboardTimeParam: string): Promise<void> {
        try {
            setLeaderboardData(undefined);
            const res = await new GoFrensApi(getConfig()).getCurrentTopLeaderboardRankings({
                leaderboardTimeParam: leaderboardTimeParam,
            });
            setLeaderboardData(res.leaderboardRankings);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function getCommunityLeadersData(leaderboardTimeParam: string): Promise<void> {
        try {
            setLeadersData(undefined);
            const res = await new GoFrensApi(getConfig()).getCommunityLeadersRankings({
                leaderboardTimeParam: leaderboardTimeParam,
            });
            setLeadersData(res.communityLeaders);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function handleDateOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        try {
            setLeaderboardData(undefined);
            const newDateValue = e.target.value;
            setDateValue(newDateValue);

            if (leaderboardView === LeaderboardView.COMMUNITIES) {
                await getLeaderboardData(`${newDateValue},${viewMode}`);
            } else {
                await getCommunityLeadersData(`${newDateValue},${viewMode}`);
            }
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    const getBgColor = (index: number, isDarkMode: boolean): LeaderboardRowColor => {
        if (!isDarkMode) {
            return index % 2 === 0
                ? "light-gray"
                : "white";
        }

        return index % 2 === 0
            ? "DM-light-gray"
            : "DM-white";
    };

    function renderLeaderboardRow(data: Array<TopLeaderboardCommunity>, start: number = 0): Array<ReactElement> {
        return data.map((community, index) => {
            return (
                <GoFrensLeaderboardRow
                    key={`leaderboard-${community.communityName}`}
                    rank={index + 1 + start}
                    profile={{
                        photo: community.communityIcon,
                        name: community.communityName,
                    }}
                    accumulatedPoints={community.totalPoints}
                    bgColor={getBgColor(index, isOnDarkMode)}
                />
            );
        });
    }

    function renderLeadersRow(data: Array<TopCommunityLeader>, start: number = 0): Array<ReactElement> {
        return data.map((leader, index) => {
            return (
                <GoFrensLeaderboardRow
                    key={`leader-${index + 1 + start}`}
                    rank={index + 1 + start}
                    profile={{
                        photo: leader.userPfpUrl,
                        name: leader?.userNickname?.length
                            ? `${leader.userNickname}`
                            : "GoVIP Player",
                    }}
                    accumulatedPoints={leader.totalUserPoints}
                    bgColor={getBgColor(index, isOnDarkMode)}
                    communityName={leader.communityName}
                    communityIcon={leader.communityIcon}
                />
            );
        });
    }

    function renderLeaderboards(): ReactElement {
        if (!leaderboardData) {
            return <GoFrensTopLeaderboardSkeleton/>;
        }

        if (leaderboardData?.length === 0) {
            return <GoFrensTopLeaderboardEmptyState/>;
        }

        return (
            <>
                <div className="gofrens-top-leaderboard_boards_right">
                    <GoFrensTopLeaderboardCommunityTableHeader/>
                    {renderLeaderboardRow(leaderboardData.slice(0, MAX_PER_TABLE))}
                </div>
                {isDesktop && leaderboardData.slice(MAX_PER_TABLE, MAX_PER_TABLE * 2).length > 0 &&
                    <div className="gofrens-top-leaderboard_boards_left">
                        <GoFrensTopLeaderboardCommunityTableHeader/>
                        {renderLeaderboardRow(leaderboardData.slice(MAX_PER_TABLE, MAX_PER_TABLE * 2), MAX_PER_TABLE)}
                    </div>
                }
            </>
        );
    }

    function renderCommunityLeaders(): ReactElement {
        if (!leadersData) {
            return <GoFrensTopLeaderboardSkeleton/>;
        }
        if (leadersData?.length === 0) {
            return <GoFrensTopLeaderboardCommunityLeadersEmptyState isCurrent={dateValue === `${currMonth},${currYear}`}/>;
        }
        return (
            <>
                <div className="gofrens-top-leaderboard_boards_right">
                    <GoFrensTopLeaderboardCommunityTableHeader/>
                    {renderLeadersRow(leadersData.slice(0, MAX_PER_TABLE))}
                </div>
                {isDesktop && leadersData.slice(MAX_PER_TABLE, MAX_PER_TABLE * 2)?.length > 0 &&
                    <div className="gofrens-top-leaderboard_boards_left">
                        <GoFrensTopLeaderboardCommunityTableHeader/>
                        {renderLeadersRow(leadersData.slice(MAX_PER_TABLE, MAX_PER_TABLE * 2), MAX_PER_TABLE)}
                    </div>
                }
            </>
        );

    }

    function renderDropdownButtons(): ReactElement {
        return (
            <>
                <div
                    className="gofrens-top-leaderboard_top-inputs_dropdown-container gofrens-top-leaderboard_top-inputs_dropdown-width-adjust"
                >
                    <FrameOneSelect
                        className="gofrens-top-leaderboard_top-inputs_dropdown-container_select"
                        disabled={!dateRange || leaderboardView === LeaderboardView.COMMUNITIES
                            ? !leaderboardData
                            : !leadersData}
                        value={dateValue}
                        onChange={async (e) => await handleDateOnChange(e)}
                    >
                        <option value={`${currMonth},${currYear}`}>Current Month</option>
                        {dateRange &&
                            <>
                                {dateRange.map((date) => {
                                    return (
                                        <option value={date.dateParam} key={date.dateParam}>{date.dateValue}</option>
                                    );
                                })}
                            </>
                        }
                    </FrameOneSelect>
                </div>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="white-drop-shadow"
                    size="normal"
                    className="gofrens-top-leaderboard_top-inputs_button"
                    onClick={toggleModal}
                    forwardProps={{
                        disabled: leaderboardView === LeaderboardView.COMMUNITIES
                            ? !leaderboardData
                            : !leadersData,
                    }}
                >
                    View Top 50
                </FrameButton>
            </>
        );
    }

    function isModalRendered(): boolean {
        if (leaderboardView === LeaderboardView.COMMUNITIES) {
            return leaderboardData !== undefined;
        } else {
            return leadersData !== undefined;
        }
    }

    return (
        <>
            {isModalRendered() &&
                <GoFrensTopLeaderboardCommunitiesModal
                    <TopLeaderboardCommunity | TopCommunityLeader>
                    isOpen={showCommunityModal}
                    onClose={toggleModal}
                    renderedData={leaderboardView === LeaderboardView.COMMUNITIES
                        ? leaderboardData
                        : leadersData}
                    renderFunction={leaderboardView === LeaderboardView.COMMUNITIES
                        ? renderLeaderboardRow
                        : renderLeadersRow}
                    dateRange={dateRange}
                    startingDateValue={dateValue}
                    currentView={leaderboardView}
                    periodViewMode={viewMode}
                />
            }

            <div className="gofrens-top-leaderboard">
                <div className="gofrens-other-communities_header">
                    <div
                        className="gofrens-other-communities_header_title-container gofrens-other-communities_header_top-leaderboard-flex-adjust">
                        <h3>Top Leaderboards</h3>

                        <div className="gofrens-other-communities_header_title-container_right">
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                className="gofrens-top-leaderboard_view-mode-button"
                                color={isOnDarkMode ? "white-drop-shadow" : "gray"}
                                size="pill"
                                onClick={toggleViewMode}
                                rightIcon={FaRankingStar}
                            >
                                <div className="gofrens-top-leaderboard_view-mode">
                                    {viewModeButtonContent()}
                                </div>
                            </FrameButton>
                        </div>

                    </div>
                </div>

                <div className="gofrens-top-leaderboard_top-inputs">
                    <div className="gofrens-top-leaderboard_top-inputs_toggle">
                        <FrameOneSwitchInput
                            <LeaderboardView>
                            name="leaderboard-view-toggle"
                            value={leaderboardView}
                            onToggle={toggleView}
                            options={[
                                {
                                    render: "Communities",
                                    value: LeaderboardView.COMMUNITIES,
                                },
                                {
                                    render: "Community Leaders",
                                    value: LeaderboardView.LEADERS,
                                },
                            ]}
                        />
                    </div>
                    {isDesktop
                        ? <>
                            <Spacer/>
                            {renderDropdownButtons()}
                        </>
                        : <div className="gofrens-top-leaderboard_top-inputs_button-container">
                            {renderDropdownButtons()}
                        </div>
                    }
                </div>

                <div className="gofrens-top-leaderboard_boards">
                    {leaderboardView === LeaderboardView.COMMUNITIES
                        ? renderLeaderboards()
                        : renderCommunityLeaders()}
                </div>
            </div>
        </>
    );
}

export default GoFrensTopLeaderboard;