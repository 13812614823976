/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftPurchase,
    NftPurchaseFromJSON,
    NftPurchaseFromJSONTyped,
    NftPurchaseToJSON,
    NftType,
    NftTypeFromJSON,
    NftTypeFromJSONTyped,
    NftTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface NftPurchaseUpsert
 */
export interface NftPurchaseUpsert extends NftPurchase {
    /**
     * 
     * @type {number}
     * @memberof NftPurchaseUpsert
     */
    dPayPricePerUsd: number;
    /**
     * 
     * @type {number}
     * @memberof NftPurchaseUpsert
     */
    dpayBalance: number;
}

export function NftPurchaseUpsertFromJSON(json: any): NftPurchaseUpsert {
    return NftPurchaseUpsertFromJSONTyped(json, false);
}

export function NftPurchaseUpsertFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftPurchaseUpsert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...NftPurchaseFromJSONTyped(json, ignoreDiscriminator),
        'dPayPricePerUsd': json['dPayPricePerUsd'],
        'dpayBalance': json['dpayBalance'],
    };
}

export function NftPurchaseUpsertToJSON(value?: NftPurchaseUpsert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...NftPurchaseToJSON(value),
        'dPayPricePerUsd': value.dPayPricePerUsd,
        'dpayBalance': value.dpayBalance,
    };
}


