import { NftRewardPrize } from "@devour/client";
import { FC, ReactElement } from "react";
import Tooltip from "../Tooltip";

interface Props {
    children: ReactElement;
    description: ReactElement | string;
    title: ReactElement | string;
    prizes: {
        id: string;
        name: string;
        quantity: number;
    }[];
}

export const GoVipRewardsNftTooltip: FC<Props> = (props: Props): ReactElement => {
    return (
        <Tooltip
            containerElementClassname="govip-rewards-drawer_reward"
            outsideContent={props.children}
            className="govip-rewards-drawer_reward_tooltip"
        >
            <div className="govip-rewards-drawer_reward_tooltip_header">{props.title}</div>

            <div className="govip-rewards-drawer_reward_tooltip_info">{props.description}</div>

            <div className="govip-rewards-drawer_reward_tooltip_prizes">
                <div className="govip-rewards-drawer_reward_tooltip_prizes_row row-header">
                    <span>Prize</span>
                    <span>Quantity</span>
                </div>
                <hr />
                {props.prizes.map((prize) => (
                    <div className="govip-rewards-drawer_reward_tooltip_prizes_row" key={prize.id}>
                        <span className="govip-rewards-drawer_reward_tooltip_prizes_row_name">{prize.name}</span>
                        <span className="govip-rewards-drawer_reward_tooltip_prizes_row_quantity">
                            {prize.quantity}
                        </span>
                    </div>
                ))}
            </div>
        </Tooltip>
    );
};
