import {ReactElement, useEffect, useState} from "react";
import CountUp from "react-countup";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {v4 as uuid} from "uuid";
import GoVipHeaderDpayCard from "./GoVipHeaderDpayCard";
import GoVipLevelsModal from "../modals/GoVipLevelsModal";
import GoVipHeaderUserDetails from "./GoVipHeaderUserDetails";
import GoVipExtendedExperienceBar from "./GoVipExtendedExperienceBar";
import GoVipRankButton from "./GoVipRankButton";
import SignUpToast from "../SignUpToast";
import {useGate} from "statsig-react";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

function GoVipHeaderAccountLevel(): ReactElement {

    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const [counterStart, setCounterStart] = useState<number>(accountLevel
        ? accountLevel.experienceGainedAtThisLevel
        : 0);
    const [counterEnd, setCounterEnd] = useState<number>(accountLevel
        ? accountLevel.experienceGainedAtThisLevel
        : 0);
    const [pointGain, setPointGain] = useState<number>(0);
    const [showGoVipLevelsModal, setShowGoVipLevelsModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const experienceGained = fullToken && accountLevel ? accountLevel.experienceGainedAtThisLevel : 0;
    const totalLevelExperience = fullToken && accountLevel ? accountLevel.experienceGainedAtThisLevel + accountLevel.experienceRequiredForNextLevel : 0;
    const {data: userProfile} = useGetUserProfile(fullToken);

    function handleToastDismissal() {
        setShowToast(false);
    }

    useEffect(() => {
        if (fullToken && accountLevel) {
            setPointGain(accountLevel.experienceGainedAtThisLevel - counterEnd);
            setCounterStart(counterEnd);
            setCounterEnd(accountLevel.experienceGainedAtThisLevel);
        }
    }, [
        fullToken?.token,
        accountLevel,
    ]);

    useEffect(() => {
        // reset state after showing so animation can replay
        if (pointGain > 0) {
            setTimeout(() => setPointGain(0), 1000);
        }
    }, [pointGain]);

    function toggleGoVipPrizesModal(): void {
        setShowGoVipLevelsModal(!showGoVipLevelsModal);
    }

    return (
        <>
            <SignUpToast
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <GoVipLevelsModal
                isOpen={showGoVipLevelsModal}
                toggle={toggleGoVipPrizesModal}
            />

            <div className="go-vip-top_header_exp">

                <div className="go-vip-top_header_desktop-adjust">

                    <div className="go-vip-top_header_desktop-adjust_left-container">
                        <GoVipHeaderUserDetails/>
                        <div className="go-vip-top_header_desktop-adjust_left-container_progress">
                            <span className="go-vip-top_header_user-details_level_container">
                                Level {fullToken && userProfile ? userProfile.user?.level : 0}
                            </span>

                            <div className="go-vip-top_header_exp_counter-container">
                                <div className="go-vip-top_header_exp_counter-container_left">
                                    <CountUp
                                        className="go-vip-top_header_exp_counter-container_counter"
                                        start={counterStart}
                                        end={counterEnd}
                                        useGrouping={false}
                                    />
                                    <span className="go-vip-top_header_exp_counter-container_points">
                                        XP
                                    </span>
                                </div>

                                {pointGain > 0 &&
                                    <p key={uuid()}
                                        className="go-vip-top_header_exp_counter-container_animated-points">
                                        {`+${pointGain}`}
                                    </p>
                                }

                                <div>
                                    <GoVipRankButton/>
                                    <p className="go-vip-top_header_exp_counter-container_exp">
                                        {experienceGained.toLocaleString()} / {totalLevelExperience.toLocaleString()}
                                    </p>
                                </div>
                            </div>
                            <GoVipExtendedExperienceBar/>
                        </div>

                    </div>

                    {(isDesktop || isTablet) && dpayStatus &&
                        <div className="go-vip-top_header_desktop-adjust_right-container">
                            <GoVipHeaderDpayCard/>
                        </div>
                    }

                </div>

                {isMobile && !isTablet && <GoVipHeaderDpayCard/>}
            </div>
        </>
    );
}

export default GoVipHeaderAccountLevel;
