import {ReactElement, useEffect, useState} from "react";
import classNames from "classnames";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {FiX} from "react-icons/fi";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import {OverwolfApi, OWGame} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useAsset} from "@/hooks/useAsset";
import FrameOneModal from "../modals/modalComponents/FrameOneModal";


interface PlayAndEarnStepsMetadata {
    title: string;
    description: string;
    image: string;
}

const PlayAndEarnTimeSteps: Array<PlayAndEarnStepsMetadata> = [
    {
        title: "1. Play Your Game for an Hour",
        description: "Launch your favorite game and start playing!",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-all-games-step1.webp`,
    },
    {
        title: "2. Head over to ‘Play’ tab",
        description: "Open our app to track how much XP you earned",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-all-games-step2.webp`,
    },
    {
        title: "3. Toggle to ‘Time Played’",
        description: "View earned XP every hour you played games",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-all-games-step3.webp`,
    },
    {
        title: "4. Check when and how you earned",
        description: "Track earned XP by time and games",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-all-games-step4.webp`,
    },
];

const PlayAndEarnQuestSteps: Array<PlayAndEarnStepsMetadata> = [
    {
        title: "1. Play games with quests",
        description: "Launch a game with special quests listed below",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-quests-step1.webp`,
    },
    {
        title: "2. Head over to ‘Play’ tab",
        description: "Open our app to check what quests are available",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-quests-step2.webp`,
    },
    {
        title: "3. Toggle to ‘Quests’",
        description: "See what game quests you can complete!",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-quests-step3.webp`,
    },
    {
        title: "4. Complete the quest — claim XP",
        description: "Track completed quests and claim your XP!",
        image: `${import.meta.env.VITE_CDN_URL}/images/play-earn-quests-step4.webp`,
    },
];

enum ModalView {
    SUPPORTED_GAMES = "SUPPORTED_GAMES",
    QUEST = "QUEST",
}

const GameIcon = ({ game }) => {
    const { data: icon } = useAsset(game?.icon);

    if (!icon) {
        return;
    }

    return (
        <div
            key={game.id}
            className="game-icon"
            style={{backgroundImage: `url(${icon?.url})`}}
        />
    );
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function OWDiscoverPlayAndEarnModal(props: Props) {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [modalView, setModalView] = useState<null | ModalView>(null);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [games, setGames] = useState<Array<OWGame>>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isOpen) {
            setCurrentStep(0);
        }
    }, [props.isOpen]);

    useEffect(() => {
        void fetchGames();
    }, []);

    async function fetchGames() {
        try {
            const gamesData = await new OverwolfApi(getConfig(fullToken)).getOverwolfDualGames();
            setGames(gamesData);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    const renderGameIcons = () => {
        // Double the icons array to create seamless loop
        const doubledIcons = [...games, ...games];

        return (
            <div className="scroll-container">
                {doubledIcons.map((game, index) => (
                    <GameIcon key={`${game.id}-${index}`} game={game} />
                ))}
            </div>
        );
    };

    function renderStep(view): ReactElement {
        const steps = view === ModalView.SUPPORTED_GAMES ? PlayAndEarnTimeSteps : PlayAndEarnQuestSteps;
        const step = steps[currentStep];
        const isLastStep = currentStep === steps.length - 1;
        const isFirstStep = currentStep === 0;

        return (
            <div className="ow-discover-earn-xp-modal_container">
                <div className="ow-discover-earn-xp-modal_text">
                    <div className="ow-discover-earn-xp-modal_text_title">
                        {step.title}
                    </div>
                    <div className="ow-discover-earn-xp-modal_text_caption">
                        {step.description}
                    </div>
                </div>

                <div
                    className="ow-discover-play-earn-modal_img"
                    style={{backgroundImage: `url(${step.image})`}}
                />

                {view === ModalView.QUEST && isFirstStep && (
                    <div className="method-icons quest-view">{renderGameIcons()}</div>
                )}

                <div className="ow-discover-earn-xp-modal_stepper">
                    <FaAngleLeft
                        className={classNames("left", {
                            arrow: !isFirstStep,
                            white: isLastStep,
                        })}
                        size={"2rem"}
                        onClick={
                            () => {
                                if (!isFirstStep) {
                                    setCurrentStep(currentStep - 1);
                                } else {
                                    setModalView(null);
                                }
                            }
                        }
                    />

                    <div className="ow-discover-earn-xp-modal_stepper_steps">
                        {PlayAndEarnTimeSteps.map((step, index) =>
                            <div
                                key={step.title}
                                className={classNames("ow-discover-earn-xp-modal_stepper_step", {
                                    active: currentStep === index,
                                })}
                                onClick={() => setCurrentStep(index + 1)}
                            />)}
                    </div>
                    <FaAngleRight
                        className={classNames("", {
                            hide: isLastStep,
                            arrow: !isLastStep,
                        })}
                        size={"2rem"}
                        onClick={
                            () => {
                                if (!isLastStep) {
                                    setCurrentStep(currentStep + 1);
                                }
                            }
                        }
                    />
                </div>
            </div>
        );
    }

    function renderMethodsToPlayAndEarn(): ReactElement {
        return (
            <div className="ow-discover-earn-xp-modal_container">
                <div className="ow-discover-earn-xp-modal_text">
                    <div className="ow-discover-earn-xp-modal_text_title">
                        Learn 2 Methods to Play and Earn
                    </div>
                    <div className="ow-discover-earn-xp-modal_text_caption">
                        Click below to learn more
                    </div>
                </div>

                <div className="ow-discover-play-earn-modal_methods">
                    <div
                        className="method"
                        onClick={() => setModalView(ModalView.SUPPORTED_GAMES)}
                    >
                        <span className="method-title">
                            Play Our Supported Games
                            <div
                                className="method-arrow"
                                style={{backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/play-earn-arrow.svg)`}}
                            />
                        </span>
                        <span className="method-caption">
                            You can play 2000+ games to earn XP for every hour of gameplay
                        </span>
                        <div
                            className="method-img"
                            style={{backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/play-earn-supported-games.webp)`}}
                        />
                    </div>
                    <div
                        className="method"
                        onClick={() => setModalView(ModalView.QUEST)}
                    >
                        <span className="method-title">
                            Complete Game Quests
                            <div
                                className="method-arrow"
                                style={{backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/play-earn-arrow.svg)`}}
                            />
                        </span>
                        <span
                            className="method-caption">
                            Play games with quests to challenge yourself and claim extra XP
                        </span>
                        <div className="method-icons">
                            {renderGameIcons()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="ow-discover-play-earn-modal"
            size="xs1"
        >
            <div
                className="ow-discover-earn-xp-modal_body"
            >
                <div className="ow-discover-earn-xp-modal_nav">
                    <div className="ow-discover-earn-xp-modal_tag">How to Play and Earn</div>

                    <FiX
                        size="1.75rem"
                        strokeWidth="0.1875rem"
                        onClick={props.onClose}
                        className="close"
                    />
                </div>
                {modalView ? renderStep(modalView) : renderMethodsToPlayAndEarn()}
            </div>
        </FrameOneModal>
    );
}
