/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of reward, currently only supports XP.
 * @export
 * @enum {string}
 */
export enum GuildRewardType {
    XP = 'XP',
    GUILDQUALIFIER = 'GUILD_QUALIFIER'
}

export function GuildRewardTypeFromJSON(json: any): GuildRewardType {
    return GuildRewardTypeFromJSONTyped(json, false);
}

export function GuildRewardTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildRewardType {
    return json as GuildRewardType;
}

export function GuildRewardTypeToJSON(value?: GuildRewardType | null): any {
    return value as any;
}

