import {ReactElement} from "react";
import {FaExternalLinkAlt} from "react-icons/fa";

export default function OWFooter(): ReactElement {
    const SURVEY_URL = "https://share.hsforms.com/1pM-BLE3hR46pP34PIt5nqAnrwoo";

    return (
        <>
            <div className="overwolf-survey_divider"/>

            <div className="overwolf-survey">
                <a
                    href={SURVEY_URL}
                    className="go-vip-ow-devourplay-banner_survey"
                    target="_blank"
                    rel="noreferrer"
                >
                    Give Feedback <FaExternalLinkAlt/>
                </a>
                <div className="overwolf-survey_text">
                    We're excited to bring you the initial version of our app and would love your
                    feedback
                    to
                    make it even better.
                </div>
            </div>
        </>
    );
}
