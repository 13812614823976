import {ReactElement} from "react";
import {useAsset} from "@/hooks/useAsset";

interface Props {
    assetId: string;
    alt?: string;
    className?: string;
}

function AssetImage(props: Props): ReactElement {

    const {data: asset} = useAsset(props.assetId);

    if (!asset) {
        return null;
    }

    return (
        <div className="asset-image">
            <img
                src={asset.url}
                alt={props.alt}
                className={props.className}
            />
        </div>
    );
}

export default AssetImage;
