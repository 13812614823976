import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {BrandMap, BrandMapColorTheme} from "@devour/client";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import FrameButton from "@/components/buttons/FrameButton";
import RestaurantSearchAddressButton from "@/pages/restaurants/components/RestaurantSearchAddressButton";
import {decrementModalCount} from "@/redux/meta/metaActions";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import Skeleton from "react-loading-skeleton";
import BrandMapAccountDrawer from "@/components/brands/BrandMapAccountDrawer";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import BrandEmailModal from "@/components/brands/BrandEmailModal";
import {BsCart3} from "react-icons/bs";

interface Props {
    brandMap: BrandMap;
    toggleShowCartPanel: () => void;
    sumQuantity: number;
}

function RestaurantMapTopBar(props: Props): ReactElement {
    const {slug} = useParams<{ slug: string }>();
    const {isFetching: isBrandMapLoading} = useGetBrandMap(slug);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: getUserProfileData} = useGetUserProfile(fullToken);
    const [showAccountDrawer, setShowAccountDrawer] = useState<boolean>(false);
    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
    const user = getUserProfileData?.user;
    const isTextDark = props.brandMap?.colorTheme === BrandMapColorTheme.DARK;

    function onCartClick(): void {
        props.toggleShowCartPanel();
    }

    function toggleEmailModal(): void {
        setShowEmailModal(!showEmailModal);
        dispatch(decrementModalCount());
    }

    function renderUnauthenticatedTopBar() {
        const logoUrl = `${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-${isTextDark ? "Purple" : "White"}.webp`;
        return (
            <>
                <img
                    src={logoUrl}
                    alt="DevourGO Logo"
                    className="restaurant-promo-header_container_logo"
                />
                <FrameButton
                    color={isTextDark ? "dark-outline" : "white-outline"}
                    size="narrow"
                    className="restaurant-promo-header_container_btns_login"
                    onClick={toggleEmailModal}
                >
                    Log in
                </FrameButton>

                <BrandEmailModal
                    isOpen={showEmailModal}
                    toggle={toggleEmailModal}
                    brandMap={props.brandMap}
                />
            </>
        );
    }

    function renderProfilePicture(): ReactElement {
        if (!fullToken) {
            return;
        }

        return (
            <div className="restaurant-promo-header_container_btns-authenticated_profile-pic">
                {user?.gravatar &&
                    <img
                        src={user?.gravatar}
                        alt="User Profile"
                    />
                }
                {user?.nftProfilePicture &&
                    <NftProfilePictureBadge size="sm" />
                }
            </div>
        );
    }

    function handleCloseAccountDrawer(): void {
        setShowAccountDrawer(false);
    }

    function renderAuthenticatedTopBar() {
        return (
            <>
                {!user || isBrandMapLoading
                    ? renderSkeleton()
                    : <>
                        <div className="restaurant-promo-header_container_search-address">
                            <RestaurantSearchAddressButton redirect={false}/>
                        </div>
                        <div className="restaurant-promo-header_container_btns-authenticated">
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                onClick={onCartClick}
                                color={isTextDark ? "gray-light" : "ghost"}
                                size="narrow"
                                leftIcon={BsCart3}
                                className="restaurant-promo-header_container_btns-authenticated_cart"
                            >
                                {props.sumQuantity}
                            </FrameButton>
                            <div
                                className="restaurant-promo-header_container_btns-authenticated_image-btn"
                                onClick={() => fullToken && setShowAccountDrawer(true)}
                            >
                                {renderProfilePicture()}
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }

    function renderSkeleton() {
        return (
            <>
                <Skeleton width={163} height={41} style={{borderRadius: "16px"}}/>
                <div className="restaurant-promo-header_container_btns-authenticated">
                    <Skeleton width={80} height={41} style={{borderRadius: "16px"}}/>
                    <div className="restaurant-promo-header_container_btns-authenticated_profile-pic">
                        <div className="react-loading-skeleton"/>
                    </div>
                </div>

            </>
        );
    }
    return (
        <div
            className="restaurant-promo-header"
            style={{backgroundColor: props.brandMap.colorBackground}}
        >
            <BrandMapAccountDrawer
                isOpen={showAccountDrawer}
                toggle={handleCloseAccountDrawer}
            />
            <div className="restaurant-promo-header_container">
                {!fullToken ? renderUnauthenticatedTopBar() : renderAuthenticatedTopBar()}
            </div>
        </div>
    );
}


export default RestaurantMapTopBar;