import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {DialCode, PhoneNumberBody, UtilsApi} from "@devour/client";
import classNames from "classnames";
import {PatternFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";
import {FiChevronDown} from "react-icons/fi";
import FrameOneSelect from "./FrameOneSelect";
import SelectOptionsFactory from "./SelectOptionsFactory";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface Props {
    value: PhoneNumberBody
    onChange: (newPhoneNumber: PhoneNumberBody) => void;
    chevronClassname?: string;
    inputClassname?: string;
}

function FrameOnePhoneNumberInput(props: Props): ReactElement {
    const { streamerModeEnabled } = useStreamerMode();
    const [
        dialCodes,
        setDialCodes,
    ] = useState<Array<DialCode>>([]);
    const [
        flagLoaded,
        setFlagLoaded,
    ] = useState(false);

    useEffect(() => {
        fetchDialCodes().then()
            .catch();
    }, []);

    /**
     * Get the list of dial codes from our api.
     *
     */
    async function fetchDialCodes(): Promise<void> {
        try {
            const res = await new UtilsApi().getListOfDialCodes();
            setDialCodes(res);
        } catch (e) {}
    }

    /**
     * Handle changing either of the values and pass the combined entry to the callback.
     *
     * @param e
     */
    function selectOnChange(e: ChangeEvent<HTMLSelectElement>): void {
        props.onChange({
            ...props.value,
            countryCode: e.target.value,
        });
    }

    /**
     * Handle changing either of the values and pass the combined entry to the callback.
     *
     * @param values
     */
    function inputOnChange(values: NumberFormatValues): void {
        props.onChange({
            ...props.value,
            nationalNumber: values.value,
        });
    }

    /**
     * Sets a "flag" when the initial flag image is loaded to avoid the html no-image icon initially during render.
     *
     */
    function onFlagLoad(): void {
        setFlagLoaded(true);
    }

    const selectedDialCode = dialCodes.find(d => d.code === props.value.countryCode);

    return (
        <div className="frame-one-phone-input">
            <div className="frame-one-phone-input_country">
                <label>
					Country Code
                </label>
                <div className="frame-one-phone-input_country_wrapper">

                    <FrameOneSelect
                        value={props.value.countryCode}
                        onChange={selectOnChange}
                    >
                        <SelectOptionsFactory
                            <DialCode>
                            options={dialCodes.map((d) => {
                                return {
                                    value: d.code,
                                    label: `${d.name} ${d.dialCode}`,
                                };
                            })}
                        />
                    </FrameOneSelect>

                    <div
                        className={classNames("frame-one-phone-input_country-overlay", {
                            "frame-one-phone-input_country-overlay_loaded": flagLoaded,
                        })}
                    >
                        <div className="frame-one-phone-input_country-overlay_wrapper">

                            <img
                                src={`https://flagcdn.com/w40/${selectedDialCode?.code?.toLowerCase()}.png`}
                                alt={`${selectedDialCode?.code} flag`}
                                className="frame-one-phone-input_country-overlay_flag"
                                onLoad={onFlagLoad}
                            />
                            <span className="frame-one-phone-input_country-overlay_dial-code">
                                {selectedDialCode?.dialCode}
                            </span>
                        </div>

                        <FiChevronDown className={`frame-one-phone-input_country-overlay_chevron ${props.chevronClassname ? props.chevronClassname : ""}`}/>

                    </div>
                </div>
            </div>
            <div className="frame-one-phone-input_input">
                <label>
					Phone Number
                </label>
                <PatternFormat
                    value={props.value.nationalNumber}
                    valueIsNumericString={true}
                    type={streamerModeEnabled ? "password" : "tel"}
                    format="### ### ####"
                    placeholder="123-123-1234"
                    className={`frame-one-phone-input_text ${props.inputClassname ? props.inputClassname : ""}`}
                    onValueChange={inputOnChange}
                />
            </div>
        </div>
    );
}

export default FrameOnePhoneNumberInput;
