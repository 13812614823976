/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOverwolfGameImages
 */
export interface CreateOverwolfGameImages {
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    devourGameLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    thumbnailImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    bannerImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    bannerImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    landingImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    landingImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameImages
     */
    timeRewardImage?: string;
}

export function CreateOverwolfGameImagesFromJSON(json: any): CreateOverwolfGameImages {
    return CreateOverwolfGameImagesFromJSONTyped(json, false);
}

export function CreateOverwolfGameImagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOverwolfGameImages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'devourGameLogo': !exists(json, 'devourGameLogo') ? undefined : json['devourGameLogo'],
        'thumbnailImage': !exists(json, 'thumbnailImage') ? undefined : json['thumbnailImage'],
        'bannerImageSmall': !exists(json, 'bannerImageSmall') ? undefined : json['bannerImageSmall'],
        'bannerImageLarge': !exists(json, 'bannerImageLarge') ? undefined : json['bannerImageLarge'],
        'landingImageSmall': !exists(json, 'landingImageSmall') ? undefined : json['landingImageSmall'],
        'landingImageLarge': !exists(json, 'landingImageLarge') ? undefined : json['landingImageLarge'],
        'timeRewardImage': !exists(json, 'timeRewardImage') ? undefined : json['timeRewardImage'],
    };
}

export function CreateOverwolfGameImagesToJSON(value?: CreateOverwolfGameImages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logo': value.logo,
        'icon': value.icon,
        'devourGameLogo': value.devourGameLogo,
        'thumbnailImage': value.thumbnailImage,
        'bannerImageSmall': value.bannerImageSmall,
        'bannerImageLarge': value.bannerImageLarge,
        'landingImageSmall': value.landingImageSmall,
        'landingImageLarge': value.landingImageLarge,
        'timeRewardImage': value.timeRewardImage,
    };
}


