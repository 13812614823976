import {ReactElement, useEffect, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {logout} from "@/redux/auth/authActions";
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {GiMoneyStack, GiThreeFriends} from "react-icons/gi";
import {IoMdWallet} from "react-icons/io";
import {FiLogIn} from "react-icons/fi";
import {FaAngleRight, FaDiscord, FaFacebook, FaInstagram, FaTwitter, FaUserAlt} from "react-icons/fa";
import {toggleLoginOpen, toggleMagicWalletsModal, toggleSidebar} from "@/redux/meta/metaActions";
import {UserType, UtilsApi, VersionResponse} from "@devour/client";
import {RiVipDiamondLine} from "react-icons/ri";
import {MdEmojiPeople} from "react-icons/md";
import {AiOutlineHeart, AiOutlineMenuUnfold} from "react-icons/ai";
import {BiRestaurant} from "react-icons/bi";
import useWindowSize from "../../hooks/useWindowSize";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import {magic} from "@/utils/magic";
import {TfiIdBadge} from "react-icons/tfi";
import SidebarIndustryPassCard from "@/components/SidebarIndustryPassCard";
import useThemePreference from "@/hooks/useThemePreference";
import {useGate} from "statsig-react";
import BrandGoBackCard from "@/components/brands/BrandGoBackCard";
import {isMobile} from "react-device-detect";
import useOverwolfInterop, {sendMessageToOW} from "@/hooks/useOverwolfInterop";

function DevourSidebar(): ReactElement {

    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const location = useLocation();
    const isOnBrandPage = location.pathname.includes("/brands/");
    const navigate = useNavigate();
    const [
        version,
        setVersion,
    ] = useState<VersionResponse>();
    const {getThemedImageUrl} = useThemePreference();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const lastBrandMap = useSelector((store: IStore) => store.metaStore.lastBrandMap);
    const isModalShown = useSelector((store: IStore) => store.metaStore.isMagicWalletsModalActive);
    const dispatch = useDispatch();

    const [windowWidth] = useWindowSize();
    const isMobileView = (windowWidth < 576) || isMobile;
    const {isOnOverwolf} = useOverwolfInterop();

    useEffect(() => {
        new UtilsApi().version()
            .then(setVersion)
            .catch(console.error);
    }, []);

    async function logoutHelper(): Promise<void> {
        toggleSidebarHelper();
        sendMessageToOW({type: "de:logout"});
        await magic.user.logout();
        dispatch(logout());
        navigate("/");
    }

    function toggleSelectMagicWalletModal(): void {
        dispatch(toggleMagicWalletsModal(!isModalShown));
    }

    function toggleSidebarHelper(): void {
        dispatch(toggleSidebar(false));
    }

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        toggleSidebarHelper();
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    }

    return (
        <div className={classNames("devour-sidebar", {
            "devour-sidebar_overwolf": isOnOverwolf,
        })}
        >
            <div className="devour-sidebar_top">
                <div
                    className="devour-sidebar_top_branding"
                    onClick={handleOrderFoodRouteForAuthedUser}
                >
                    <img
                        src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-01.webp`)}
                        alt="DevourGO Logo"
                    />
                </div>
                <div className="devour-sidebar_top_title">
                    Menu
                </div>
                <div className="devour-sidebar_top_navigation">
                    {fullToken
                        ? <>
                            {isMobileView && !isOnBrandPage && <BrandGoBackCard isMobileCard={true}/>}
                            <FrameButton
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-order-food", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/restaurants"),
                                })}
                                leftIcon={BiRestaurant}
                                rightIcon={FaAngleRight}
                                onClick={handleOrderFoodRouteForAuthedUser}
                            >
                                Order Food
                            </FrameButton>

                            <FrameButton
                                to="/favorites"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-saved-restaurants", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/favorites"),
                                })}
                                leftIcon={AiOutlineHeart}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                Favorites
                            </FrameButton>

                            <FrameButton
                                to="/menu-orders"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-orders", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/menu-orders"),
                                })}
                                leftIcon={AiOutlineMenuUnfold}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                Orders
                            </FrameButton>

                            <FrameButton
                                to="/go-vip-dashboard"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-govip", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/go-vip-dashboard"),
                                })}
                                leftIcon={RiVipDiamondLine}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                GoVIP
                            </FrameButton>

                            <FrameButton
                                to="/gofriends"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-gofrens", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/gofriends"),
                                })}
                                leftIcon={MdEmojiPeople}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                GoFriends
                            </FrameButton>

                            <FrameButton
                                to="/the-industry-pass"
                                color="ghost"
                                size="normal"
                                className={classNames(
                                    "devour-sidebar_top_navigation_button",
                                    "devour-sidebar_top_navigation_button-gofrens", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/the-industry-pass"),
                                    },
                                )}
                                leftIcon={TfiIdBadge}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                Industry Pass
                            </FrameButton>

                            {dpayStatus &&
                                <FrameButton
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", /* "devour-sidebar_top_navigation_button-dpay", */ {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/load-fuel"),
                                    })}
                                    leftIcon={GiMoneyStack}
                                    rightIcon={FaAngleRight}
                                    to={"/load-fuel"}
                                    onClick={toggleSidebarHelper}
                                >
                                    Load {import.meta.env.VITE_TOKEN_NAME}
                                </FrameButton>
                            }

                            <FrameButton
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-wallet")}
                                leftIcon={IoMdWallet}
                                rightIcon={FaAngleRight}
                                onClick={toggleSelectMagicWalletModal}
                            >
                                Wallet
                            </FrameButton>
                            <FrameButton
                                to="/account"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-account", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/account"),
                                })}
                                leftIcon={FaUserAlt}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                Account
                            </FrameButton>

                            {currentUser?.user?.type === UserType.PARTNER
                                ? <FrameButton
                                    to={windowWidth > 768
                                        ? "/account/#affiliates"
                                        : "/affiliates"}
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-account", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/affiliates"),
                                    })}
                                    leftIcon={GiThreeFriends}
                                    rightIcon={FaAngleRight}
                                    onClick={toggleSidebarHelper}
                                >
                                    Affiliates
                                </FrameButton>
                                : <FrameButton
                                    to={windowWidth > 768
                                        ? "/account/#referafriend"
                                        : "/refer-a-friend"}
                                    color="ghost"
                                    size="normal"
                                    className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-account", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/refer-a-friend"),
                                    })}
                                    leftIcon={GiThreeFriends}
                                    rightIcon={FaAngleRight}
                                    onClick={toggleSidebarHelper}
                                >
                                    Refer-A-Friend
                                </FrameButton>
                            }

                            <FrameButton
                                color="ghost"
                                size="normal"
                                className="devour-sidebar_top_navigation_button devour-sidebar_top_navigation_button-logout"
                                // leftIcon={FiLogOut}
                                onClick={logoutHelper}
                            >
                                Log Out
                            </FrameButton>
                        </>
                        : <>
                            {isMobileView && !isOnBrandPage && <BrandGoBackCard isMobileCard={true}/>}
                            <FrameButton
                                to="/restaurants"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-order-food", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/restaurants"),
                                })}
                                leftIcon={BiRestaurant}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                Order Food
                            </FrameButton>

                            <FrameButton
                                to="/go-vip-dashboard"
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-govip", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/go-vip-dashboard"),
                                })}
                                leftIcon={RiVipDiamondLine}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                GoVIP
                            </FrameButton>

                            <FrameButton
                                to="/gofriends"
                                color="ghost"
                                size="normal"
                                className={classNames(
                                    "devour-sidebar_top_navigation_button",
                                    "devour-sidebar_top_navigation_button-gofrens", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/gofriends"),
                                    },
                                )}
                                leftIcon={MdEmojiPeople}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                GoFriends
                            </FrameButton>

                            <FrameButton
                                to="/the-industry-pass"
                                color="ghost"
                                size="normal"
                                className={classNames(
                                    "devour-sidebar_top_navigation_button",
                                    "devour-sidebar_top_navigation_button-gofrens", {
                                        "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/the-industry-pass"),
                                    },
                                )}
                                leftIcon={TfiIdBadge}
                                rightIcon={FaAngleRight}
                                onClick={toggleSidebarHelper}
                            >
                                Industry Pass
                            </FrameButton>

                            <FrameButton
                                color="ghost"
                                size="normal"
                                className={classNames("devour-sidebar_top_navigation_button", "devour-sidebar_top_navigation_button-login", {
                                    "devour-sidebar_top_navigation_button_selected": location.pathname.includes("/log-in"),
                                })}
                                leftIcon={FiLogIn}
                                rightIcon={FaAngleRight}
                                onClick={() => {
                                    dispatch(toggleLoginOpen(true));
                                    toggleSidebarHelper();
                                }}
                            >
                                Log In
                            </FrameButton>
                        </>
                    }
                </div>
            </div>

            <div className="devour-sidebar_bottom">
                {lastBrandMap && !isOnBrandPage ? <BrandGoBackCard/> : <SidebarIndustryPassCard/>}

                {version &&
                    <p className="devour-sidebar_bottom_version">
                        Version: {version.version}
                    </p>
                }

                <div className="devour-sidebar_bottom_socials">
                    <FrameButton
                        color="gray"
                        size="icon-square"
                        leftIcon={FaTwitter}
                        href="https://twitter.com/GoDevour"
                        className="devour-sidebar_bottom_socials_btn"
                    />

                    <FrameButton
                        color="gray"
                        size="icon-square"
                        leftIcon={FaInstagram}
                        href="https://www.instagram.com/go_devour/"
                        className="devour-sidebar_bottom_socials_btn"
                    />

                    <FrameButton
                        color="gray"
                        size="icon-square"
                        leftIcon={FaFacebook}
                        href="https://www.facebook.com/GoDevour"
                        className="devour-sidebar_bottom_socials_btn"
                    />
                    <FrameButton
                        color="gray"
                        size="icon-square"
                        leftIcon={FaDiscord}
                        href="https://discord.com/invite/rfvvuDnPG5"
                        className="devour-sidebar_bottom_socials_btn"
                    />
                </div>
            </div>
        </div>
    );
}

export default DevourSidebar;
