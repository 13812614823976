import useGetOverwolfArt, { OverwolfArtType } from "@/hooks/useGetOverwolfArt";

const OverwolfGameLogo = () => {
    const { getOverwolfArt, hasCustomLogo } = useGetOverwolfArt(OverwolfArtType.devourLogo);

    return (
        <div id={hasCustomLogo ? "overwolf-game-logo" : ""}>
            <img
                src={getOverwolfArt}
                alt="Devour logo"
            />
        </div>
    );
};

export default OverwolfGameLogo;
