import {OverwolfApi, OWGame} from "@devour/client";
import {ReactElement, useEffect, useState} from "react";
import getConfig from "@/utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";
import FrameButton from "@/components/buttons/FrameButton";
import Tooltip from "@/components/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import {GameCard} from "../goVip/GoVipOWDualRewardingGames";

export default function OWComingSoonGames(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const [games, setGames] = useState<Array<OWGame>>([]);
    const comingSoonGames = games.filter(game => !game.isEventSupportReady);
    const comingSoonButton = () => {
        return <FrameButton
            size="normal"
            className="go-vip-overwolf_dual-rewards_game_coming-soon"
        >
            Coming Soon
        </FrameButton>;
    };

    useEffect(() => {
        void fetchGames();
    }, []);

    async function fetchGames() {
        try {
            const gamesData = await new OverwolfApi(getConfig(fullToken)).getOverwolfDualGames();
            setGames(gamesData);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    if (!games.length || !comingSoonGames.length) {
        return null;
    }

    return (
        <div className="go-vip-overwolf_rewards">
            <h3 className="overwolf-discover-page_coming-soon">
                Coming Soon
                <Tooltip
                    className="overwolf-govip_rewards_header_tooltip"
                    outsideIcon={BsFillQuestionCircleFill}
                    containerElementClassname="overwolf-govip_rewards"
                >
                    <div className="overwolf-govip_rewards_header_tooltip_content">
                        Games listed below are games that our team is currently working on to add fun and challenging quests for you to complete and claim rewards!
                    </div>
                </Tooltip>
            </h3>

            <div className="go-vip-overwolf_dual-rewards">
                {comingSoonGames.map(game => (
                    <GameCard key={game.id} game={game} getGameButton={comingSoonButton}/>
                ))}
            </div>
        </div>
    );
}
