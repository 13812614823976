import { ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import { isDesktop, isTablet } from "react-device-detect";
import { FiX } from "react-icons/fi";
import Spacer from "@/components/Spacer";
import FrameButton from "@/components/buttons/FrameButton";
import { BsChevronLeft } from "react-icons/bs";
import useWindowSize from "@/hooks/useWindowSize";
import FrameOneModal from "../modals/modalComponents/FrameOneModal";

interface GoVipOWTutorialStepMetadata {
    title: string;
    description: ReactElement | string;
    image: ReactElement;
}

const goVipOWTutorialSteps: Array<GoVipOWTutorialStepMetadata> = [
    {
        title: "Launch Your Game",
        description: (
            <>
                Press <b>Alt + D</b> to bring up the app in game and start your journey!
            </>
        ),
        image: (
            <img src={`${import.meta.env.VITE_CDN_URL}/images/ow-introduction-step-1.webp`} alt="Launch Your Game" />
        ),
    },
    {
        title: "Earn XP By Playing Games 💎",
        description: "Complete quests, rack up playtime, or order food to earn XP and rank up.",
        image: (
            <img
                src={`${import.meta.env.VITE_CDN_URL}/images/ow-introduction-step-2.webp`}
                alt="Earn XP By Playing Games"
            />
        ),
    },
    {
        title: "Level Up! Unlock Rewards 🎁",
        description: "Level up and get discounts, merch, FUEL tokens, or loot boxes — prizes!",
        image: (
            <img
                src={`${import.meta.env.VITE_CDN_URL}/images/ow-introduction-step-3.webp`}
                alt="Level Up! Unlock Rewards"
            />
        ),
    },
    {
        title: "Redeem! Order Food 🍕",
        description: "Use your promo codes or FUEL tokens to save on your next meal!",
        image: (
            <img src={`${import.meta.env.VITE_CDN_URL}/images/ow-introduction-step-4.webp`} alt="Redeem! Order Food" />
        ),
    },
];

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function OwIntroductionModal(props: Props) {
    const [currentStep, setCurrentStep] = useState<number>(0);

    useEffect(() => {
        preloadImages(); // Preload images to fix flickering
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            setCurrentStep(0);
        }
    }, [props.isOpen]);

    function preloadImages() {
        goVipOWTutorialSteps.forEach((step) => {
            const img = new Image();
            img.src = step.image.props.src;
        });
    }

    function getImageUrl(name: string): string {
        return `${import.meta.env.VITE_CDN_URL}/images/ow-introduction-${name}.webp`;
    }

    function renderStep(): ReactElement {
        const step = goVipOWTutorialSteps[currentStep - 1];
        const isLastStep = currentStep === goVipOWTutorialSteps.length;

        return (
            <div className="go-vip-ow-introduction-modal_body_step-container">
                <div className="go-vip-ow-introduction-modal_body_step">
                    <div className="go-vip-ow-introduction-modal_body_step_info">
                        <b className="go-vip-ow-introduction-modal_body_step_number">0{currentStep}</b>
                        <h3>{step.title}</h3>
                        <div>{step.description}</div>
                    </div>
                    {step.image}
                </div>

                <div className="go-vip-ow-introduction-modal_body_step_stepper">
                    {goVipOWTutorialSteps.map((step, index) => (
                        <div
                            key={step.title}
                            className={classNames("go-vip-ow-introduction-modal_body_step_stepper_step", {
                                active: currentStep === index + 1,
                            })}
                            onClick={() => setCurrentStep(index + 1)}
                        />
                    ))}
                </div>

                <FrameButton
                    size="normal"
                    color="devourplay-gradient"
                    className="go-vip-ow-introduction-modal_body_step_next-btn"
                    onClick={() => {
                        if (isLastStep) {
                            props.onClose();
                        } else {
                            setCurrentStep(currentStep + 1);
                        }
                    }}
                >
                    {isLastStep ? "Get Started!" : "Next"}
                </FrameButton>
            </div>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="go-vip-ow-introduction-modal"
            size="xs2"
        >
            <div className={classNames("go-vip-ow-introduction-modal_body", `step-${currentStep}`)}>
                <div className="go-vip-ow-introduction-modal_body_bg-initial" />
                <div
                    style={{ backgroundImage: `url(${getImageUrl("bnr")})` }}
                    className="go-vip-ow-introduction-modal_body_bg"
                />
                <div className="go-vip-ow-introduction-modal_nav">
                    {currentStep > 0 && (
                        <button onClick={() => setCurrentStep(currentStep - 1)}>
                            <BsChevronLeft size="1.5rem" strokeWidth="0.07rem" />
                        </button>
                    )}
                    <Spacer />
                    <button>
                        <FiX size="1.75rem" strokeWidth="0.1875rem" onClick={props.onClose} />
                    </button>
                </div>

                {currentStep === 0 && (
                    <div className="go-vip-ow-introduction-modal_body_initial">
                        <div className="go-vip-ow-introduction-modal_body_initial_header">
                            <h1 className="go-vip-ow-introduction-modal_body_initial_title">Welcome</h1>
                            <h1 className="go-vip-ow-introduction-modal_body_initial_subtitle">
                                Ready to Start
                                <br />
                                Devour & Play
                            </h1>
                            <p className="go-vip-ow-introduction-modal_body_initial_description">
                                DevourPlay lets you earn XP, unlock rewards, and order food while gaming. Ready to see
                                how it works?
                            </p>
                        </div>
                        <FrameButton
                            size="normal"
                            color="devourplay-gradient"
                            className="go-vip-ow-introduction-modal_body_initial_explore-btn"
                            onClick={() => setCurrentStep(currentStep + 1)}
                        >
                            Lets go!
                        </FrameButton>
                    </div>
                )}
                {currentStep > 0 && renderStep()}
            </div>
        </FrameOneModal>
    );
}
