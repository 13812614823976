import { IStore } from "@/redux/defaultStore";
import { MetaAction } from "@/redux/meta/metaReducer";
import { useDispatch, useSelector } from "react-redux";

export const useStreamerMode = () => {
    const dispatch = useDispatch();
    const streamerModeEnabled = useSelector((state: IStore) => state.metaStore.streamerModeEnabled);
    const setSteamerMode = (enabled: boolean) => {
        dispatch({
            type: MetaAction.TOGGLE_STREAMER_MODE_ENABLED,
            payload: enabled,
        });
    };
    const hideCharacters = (text: string, startAt: number = 0) => {
        if (!text) return "";
        return streamerModeEnabled
            ? text.substring(0, startAt) + text.substring(startAt).replace(/[^ .,]/g, "*")
            : text;
    };
    const hideInputText = (defaultType: React.HTMLInputTypeAttribute = "text") => {
        const type = streamerModeEnabled ? "password" : defaultType;
        return { type };
    };

    const blurContainer = (blurRatio = 20) => {
        if (streamerModeEnabled) {
            return {style: {
                filter: `blur(${blurRatio}px)`,
            } };
        }
        return { };
    };

    return {
        streamerModeEnabled,
        setSteamerMode,
        hideCharacters,
        hideInputText,
        blurContainer,
    };
};
