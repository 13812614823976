import {ReactElement, useState} from "react";
import {AddressBook, AddressBooksApi, UsersApi} from "@devour/client";
import {FiTrash} from "react-icons/fi";
import FrameButton from "./buttons/FrameButton";
import {FaCheckCircle} from "react-icons/fa";
import classNames from "classnames";
import getConfig from "@/utils/getConfig";
import {addError, updateCurrentUser} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {MdOutlineModeEdit} from "react-icons/md";
import useThemePreference from "@/hooks/useThemePreference";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface Props {
    addressBook: AddressBook;
    selected?: boolean;
    onSelect?: (addressBook: AddressBook) => void;
    onUpdateAddress: (addressBook: AddressBook) => void;
    showDelete?: boolean;
}

function ManageAddressBookModalAddressDisplay(props: Props): ReactElement {

    const dispatch = useDispatch();
    const { hideCharacters } = useStreamerMode();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnDarkMode } = useThemePreference();
    const [
        showUpdateModal,
        setShowUpdateModal,
    ] = useState<boolean>(false);

    async function confirmRemoveAddress(): Promise<void> {

        try {
            await new AddressBooksApi(getConfig(fullToken)).deleteAddressBook({
                id: props.addressBook?.id,
            });

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function toggleModal(): void {
        props.onUpdateAddress?.(props.addressBook);
    }

    return (
        <div className="mab-address-display">
            <button
                className={classNames("mab-address-display_left", {
                    "is-selected": props.selected,
                })}
                onClick={() => props.onSelect?.(props.addressBook)}
            >

                <div className="mab-address-display_content">
                    <p className="mab-address-display_content_title">
                        {hideCharacters(props.addressBook.line1)}, {hideCharacters(props.addressBook.locality)}, {hideCharacters(props.addressBook.administrativeArea)}
                    </p>
                </div>

                {props.addressBook.isDefault &&
                    <div className="mab-address-display_default">
                        Default
                    </div>
                }
                {props.selected &&
                    <div className="mab-address-display_selected">
                        <FaCheckCircle/>
                    </div>
                }
            </button>

            <div className="mab-address-display_right">

                <div className="mab-address-display_edit">
                    <FrameButton
                        color="purple-text-no-border"
                        size="icon"
                        leftIcon={MdOutlineModeEdit}
                        onClick={toggleModal}
                    />
                </div>
                {props.showDelete &&
                    <div className="mab-address-display_delete">
                        <FrameButton
                            color="danger"
                            size="icon"
                            leftIcon={FiTrash}
                            onClick={confirmRemoveAddress}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default ManageAddressBookModalAddressDisplay;
