import {ReactElement, ReactNode} from "react";
import {MenuCustomization, MenuCustomizationItem, MenuOrderSubItem} from "@devour/client";
import FrameOneRadio from "./inputs/FrameOneRadio";
import FrameOneCheckbox from "./inputs/FrameOneCheckbox";
import classNames from "classnames";
import {cloneDeep} from "lodash";
import {NumberFormatValues} from "react-number-format/types/types";
import {htmlDecode} from "../utils/htmlDecode";
import Accordion from "@/components/Accordion";
import Spacer from "@/components/Spacer";
import {HiExclamationCircle} from "react-icons/hi";
import getNestedDefaultCustomizationItems from "@/utils/getNestedDefaultCustomizationItems";
import { QuantityStepper } from "./stepper/QuantityStepper";

interface Props {
    customization: MenuCustomization;
    parentCustomizationArray: Array<MenuOrderSubItem>;
    onUpdate: (customizations: Array<MenuOrderSubItem>) => void;
    accordion?: boolean;
    invalid?: boolean;
}

function MenuItemCustomizationFormCustomizationSelection(props: Props): ReactElement {
    // Customer's selected options in this customization group
    const selectedItemsInGroup = props.parentCustomizationArray
        .filter((item) => props.customization.options.find((c) => c.id === item.menuCustomizationItemId));
    // Total quantity of selected items in this customization group
    const totalQuantity = selectedItemsInGroup.reduce((a, b) => a + b.quantity, 0);

    /**
     * Append nested customization selections to parent object.
     * @param newNestedCustomizations
     * @param customizationItemId
     */
    function nestedCustomizationsOnUpdate(newNestedCustomizations: Array<MenuOrderSubItem>, customizationItemId: string) {
        // Attempt to find this option in the list of parent customization array
        const newCustomizations: Array<MenuOrderSubItem> = cloneDeep(props.parentCustomizationArray);
        const thisCustomizationIndex = newCustomizations.findIndex((option) => option.menuCustomizationItemId === customizationItemId);

        newCustomizations[thisCustomizationIndex].customizations = newNestedCustomizations;

        props.onUpdate(newCustomizations);
    }

    function quantityStepperOnChange(value: number, customization: MenuCustomization, customizationItem: MenuCustomizationItem) {
        if (value == 0) {
            checkboxOnToggle(customization, customizationItem.id);
            return;
        }
        onQuantityUpdate({
            floatValue: value,
            formattedValue: value.toString(),
            value: value.toString(),
        }, customizationItem.id);
    }

    /**
     * Handle all radio input onChange events.
     *
     * @param customization
     * @param customizationItemId
     */
    function radioOnToggle(customization: MenuCustomization, customizationItemId: string): void {

        /*
         * Remove all selected items in this customization group
         * We add "this" option back after this
         */
        const newCustomizations: Array<MenuOrderSubItem> = props.parentCustomizationArray.filter((selectedItem) => {
            // Return selected items that do NOT belong to this customization group.
            return !customization.options?.find((option) => selectedItem.menuCustomizationItemId === option.id);
        });

        const currentCustomization = customization.options?.find(option => option.id === customizationItemId);

        // If multiple quantities allowed, assign the maximum allowed to this option
        const newQuantity: number = customization.multipleQuantities
            ? customization.maxAggregate
            : 1;

        // Add this option
        newCustomizations.push({
            menuCustomizationItemId: customizationItemId,
            quantity: newQuantity,
            price: currentCustomization.price, // Price property is required but will be overwritten by backend
            taxRate: 0, // Tax rate property is required but will be overwritten by backend
            name: currentCustomization.name, // Name property is required but will be overwritten by backend
            customizations: getNestedDefaultCustomizationItems(currentCustomization.customizations),
        });

        props.onUpdate(newCustomizations);
    }

    /**
     * Handle all checkbox input onChange events.
     *
     * @param customization
     * @param customizationItemId
     */
    function checkboxOnToggle(customization: MenuCustomization, customizationItemId: string): void {
        const newCustomizations: Array<MenuOrderSubItem> = cloneDeep(props.parentCustomizationArray);
        // The index of this option in the selected items array
        const thisIndex = newCustomizations.findIndex((option) => option.menuCustomizationItemId === customizationItemId);
        const quantityIncrement = customization.quantityIncrement || 1;
        // The index of a selected customization item in this customization group with more than 1 quantity
        const multipleQuantityIndex = newCustomizations.findIndex((option) => {
            // `selectedItemsInGroup` is the customization items in the entire menu item, not just this customization group. Need to filter by this group.
            const findOptionInSelectedGroup = selectedItemsInGroup.find((c) => c.menuCustomizationItemId === option.menuCustomizationItemId);
            return findOptionInSelectedGroup && option.quantity > quantityIncrement;
        });

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newCustomizations.splice(thisIndex, 1);
        } else {
            const currentCustomization = customization.options?.find(option => option.id === customizationItemId);

            if (!customization.maxAggregate || customization.maxAggregate > totalQuantity) { // If maximum amount not met yet
                // If multiple quantities allowed, assign the maximum allowed to this option
                const newQuantity: number = 1;
                // Add this option
                newCustomizations.push({
                    menuCustomizationItemId: customizationItemId,
                    quantity: newQuantity,
                    price: currentCustomization.price, // Price property is required but will be overwritten by backend
                    taxRate: 0, // Tax rate property is required but will be overwritten by backend
                    name: "", // Name property is required but will be overwritten by backend
                    customizations: getNestedDefaultCustomizationItems(currentCustomization.customizations),
                });
            } else if (multipleQuantityIndex > -1) { // If there are items with multiple quantities
                // remove one quantity from the option with multiple
                newCustomizations[multipleQuantityIndex].quantity = newCustomizations[multipleQuantityIndex].quantity - quantityIncrement;
                // Add this option
                newCustomizations.push({
                    menuCustomizationItemId: customizationItemId,
                    quantity: quantityIncrement,
                    price: currentCustomization.price, // Price property is required but will be overwritten by backend
                    taxRate: 0, // Tax rate property is required but will be overwritten by backend
                    name: currentCustomization.name, // Name property is required but will be overwritten by backend
                    customizations: getNestedDefaultCustomizationItems(currentCustomization.customizations),
                });

            }
        }

        props.onUpdate(newCustomizations);
    }

    function onQuantityUpdate(value: NumberFormatValues, customizationItemId: string) {
        // Attempt to find this option in the list of parent customization array
        const newCustomizations: Array<MenuOrderSubItem> = cloneDeep(props.parentCustomizationArray);
        const thisCustomizationIndex = newCustomizations.findIndex((option) => option.menuCustomizationItemId === customizationItemId);

        newCustomizations[thisCustomizationIndex].quantity = value.floatValue || 0;

        props.onUpdate(newCustomizations);
    }

    /**
     * Validate that customization group does not have a max allowed amount, or current total quantity is less than the current max amount
     * @param customization
     * @param oldItemQuantity
     * @param customizationItem
     * @param value
     */
    function validateQuantity(customization: MenuCustomization, customizationItem: MenuCustomizationItem, oldItemQuantity: number, value: NumberFormatValues): boolean {
        // Allow any quantity if max amount not set
        if (!customization.maxAggregate && !customizationItem.maxAllowed) {
            return true;
        }
        // Get minimum non-zero value from list of max quantity
        const maxQuantityFlags: Array<number> = [customization.maxAggregate, customizationItem.maxAllowed].filter(Boolean);
        const maxQuantity: number = Math.min.apply(null, maxQuantityFlags);
        // Subtract the old item quantity from the old total quantity to negate "this item" quantity, then add new attempted quantity and compare
        const newTotalQuantity = totalQuantity - oldItemQuantity + (value.floatValue || 0);
        return maxQuantity >= newTotalQuantity;
    }

    function renderCheckboxRadio(customization: MenuCustomization, customizationItem: MenuCustomizationItem): ReactNode {
        if (!customizationItem.isEnabled) {
            return null;
        }
        const thisCustomizationItem = props.parentCustomizationArray?.find((c) => c.menuCustomizationItemId === customizationItem.id);
        return (
            <div
                key={customizationItem.id}
                className="menu-item-customization-form_group_input"
            >
                <div className="menu-item-customization-form_group_input_row">
                    <div className="menu-item-customization-form_group_input_row_button">
                        {customization.minSelects === 1 && customization.maxSelects === 1
                            ? <FrameOneRadio
                                value={customizationItem.id}
                                onToggle={() => radioOnToggle(customization, customizationItem.id)}
                                checked={!!thisCustomizationItem}
                                name={customization.id}
                                disabled={!customizationItem.isEnabled}
                            >
                                {htmlDecode(customizationItem?.name)}
                                {customizationItem.price !== 0 && ` (+$${customizationItem.price.toFixed(2)})`}
                            </FrameOneRadio>
						 : <FrameOneCheckbox
                                onToggle={() => checkboxOnToggle(customization, customizationItem.id)}
                                checked={!!thisCustomizationItem}
                                background="purple"
                                disabled={!customizationItem.isEnabled || !thisCustomizationItem && customization.maxSelects && selectedItemsInGroup.length >= customization.maxSelects}
                            >
                                {htmlDecode(customizationItem?.name)}
                                {customizationItem.price !== 0 && ` (+$${customizationItem.price.toFixed(2)})`}
                            </FrameOneCheckbox>
                        }
                    </div>
                    {customization.multipleQuantities &&
						<div className="menu-item-customization-form_group_input_row_quantity">
						    {thisCustomizationItem &&
                                <QuantityStepper
                                    value={thisCustomizationItem.quantity}
                                    onChange={(value) => quantityStepperOnChange(value, customization, customizationItem)}
                                    max={customization.maxChoice}
                                    canIncrement={(value) => validateQuantity(customization, customizationItem, thisCustomizationItem.quantity, {floatValue: value,
                                        formattedValue: value.toString(),
                                        value: value.toString()})}
                                    step={customization.quantityIncrement || 1}
                                />
						    }
						</div>
                    }
                </div>
                {customizationItem.customizations?.sort((a, b) => a.sortOrder - b.sortOrder).map((nestedCustomization) =>
                    <div
                        key={nestedCustomization.id}
                        className={classNames("menu-item-customization-form_group_input_nested", {
                            "is-parent-checked": thisCustomizationItem,
                        })}
                    >
                        <MenuItemCustomizationFormCustomizationSelection
                            customization={nestedCustomization}
                            parentCustomizationArray={thisCustomizationItem?.customizations || []}
                            onUpdate={(newCustomizations) => nestedCustomizationsOnUpdate(newCustomizations, customizationItem.id)}
                        />
                    </div>)}
            </div>
        );
    }

    function renderLimits(menuCustomization: MenuCustomization): ReactNode {
        const strings: Array<string> = [];
        if (menuCustomization.minSelects || menuCustomization.maxSelects) {
            if (menuCustomization.minSelects === menuCustomization.maxSelects) {
                strings.push(`Select ${menuCustomization.minSelects} option${menuCustomization.minSelects > 1
                    ? "s"
                    : ""}.`);
            } else if (menuCustomization.minSelects > 0 && menuCustomization.maxSelects > 0) {
                strings.push(`Select ${menuCustomization.minSelects} to ${menuCustomization.maxSelects} options.`);
            } else if (menuCustomization.minSelects > 0) {
                strings.push(`Select at least ${menuCustomization.minSelects} options.`);
            } else if (menuCustomization.maxSelects > 0) {
                strings.push(`Select up to ${menuCustomization.maxSelects} options.`);
            }
        }

        if (menuCustomization.multipleQuantities &&
			(menuCustomization.minAggregate || menuCustomization.maxAggregate) &&
			(menuCustomization.minSelects !== menuCustomization.minAggregate ||
				menuCustomization.maxSelects !== menuCustomization.maxAggregate)) {
            if (menuCustomization.minAggregate === menuCustomization.maxAggregate) {
                strings.push(`Required quantity of ${menuCustomization.minAggregate}.`);
            } else if (menuCustomization.minAggregate > 0 && menuCustomization.maxAggregate > 0) {
                strings.push(`Quantity between ${menuCustomization.minAggregate} to ${menuCustomization.maxAggregate}.`);
            } else if (menuCustomization.minAggregate > 0) {
                strings.push(`Minimum quantity of ${menuCustomization.minAggregate}.`);
            } else if (menuCustomization.maxAggregate > 0) {
                strings.push(`Maximum quantity of ${menuCustomization.maxAggregate}.`);
            }
        }

        if (menuCustomization.multipleQuantities &&
			(menuCustomization.minChoice || menuCustomization.maxChoice) &&
			(menuCustomization.minSelects !== menuCustomization.minChoice ||
				menuCustomization.maxSelects !== menuCustomization.maxChoice)) {
            if (menuCustomization.minChoice === menuCustomization.maxChoice) {
                strings.push(`Individual option quantity of ${menuCustomization.maxChoice}.`);
            } else if (menuCustomization.minChoice > 0 && menuCustomization.maxChoice > 0) {
                strings.push(`Individual option quantity between ${menuCustomization.minChoice} to ${menuCustomization.maxChoice}.`);
            } else if (menuCustomization.minChoice > 0) {
                strings.push(`Minimum individual option quantity of ${menuCustomization.minChoice}.`);
            } else if (menuCustomization.maxChoice > 0) {
                strings.push(`Maximum individual option quantity of ${menuCustomization.maxChoice}.`);
            }
        }

        if (menuCustomization.quantityIncrement && menuCustomization.quantityIncrement !== 1) {
            strings.push(`Multiples of ${menuCustomization.quantityIncrement}.`);
        }

        if (strings.length > 0) {
            return (
                <div className="menu-item-customization-form_group_limit">
                    {strings.join(" ")}
                </div>
            );
        }
    }

    function renderBody(): ReactElement {
        return (
            <>
                {props.customization.description &&
					<div className="menu-item-customization-form_group_description">
					    {props.customization.description}
					</div>
                }

                {renderLimits(props.customization)}

                <div className="menu-item-customization-form_group_list">
                    {props.customization.options.sort((a, b) => a.sortOrder - b.sortOrder).map((item) => renderCheckboxRadio(props.customization, item))}
                </div>
            </>
        );
    }

    function renderHeader(): ReactElement {
        return (
            <div className="menu-item-customization-form_group_header">
                <h4 className="menu-item-customization-form_group_header_title">{props.customization?.name}</h4>
                <Spacer />
                <div className="menu-item-customization-form_group_details">
                    {props.customization.minSelects > 0
                        ? <div
                            className={`menu-item-customization-form_group_details_required-con${props.invalid
                                ? " invalid"
                                : ""}`}>
                            <div className="menu-item-customization-form_group_details_required-con_label">
                                {props.invalid
                                    ? <HiExclamationCircle/>
                                    : ""} Required
                            </div>
                        </div>
					 : <div className="menu-item-customization-form_group_details_optional">
						Optional
                        </div>}
                </div>
            </div>
        );
    }

    if (!props.customization) {
        return null;
    }

    return (
        <>
            {props.accordion
                ? <div className="menu-item-customization-form_group">
                    <Accordion
                        header={renderHeader()}
                        body={renderBody()}
                        showChevron={true}
                        padding="0"
                        isOpenDefault={true}
                    />
                </div>

			 : <div className="menu-item-customization-form_group">
                    {renderHeader()}
                    {renderBody()}
                </div>
            }
        </>
    );
}

export default MenuItemCustomizationFormCustomizationSelection;
