import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {Address, AddressBook, CreateAddressBookBody} from "@devour/client";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../buttons/FrameButton";
import ManageAddressBookUpdateAddressModal from "@/components/modals/ManageAddressBookUpdateAddressModal";
import {RiErrorWarningLine} from "react-icons/ri";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";

interface Props {
    isOpen: boolean;
    address: Address;
    onDone: (address: Address) => void;
    onClose: () => void;
}

function GeolocationAddressBookSaveModal(props: Props): ReactElement {

    const [
        updateFormModal,
        setUpdateFormModal,
    ] = useState<boolean>(false);
    const [
        selectedAddressBook,
        setSelectedAddressBook,
    ] = useState<AddressBook>();

    function onSaveCurrent() {
        setSelectedAddressBook({
            ...props.address,
            isDefault: false,
            id: undefined,
            handoffInstructions: undefined,
            user: undefined,
        });
        setUpdateFormModal(true);
    }

    function onSaveSubmit(address: CreateAddressBookBody) {
        setUpdateFormModal(false);
        props.onDone(address);
    }

    function onNotSave() {
        setUpdateFormModal(false);
        props.onDone(props.address);
    }

    return (
        <>
            <ManageAddressBookUpdateAddressModal
                isOpen={updateFormModal}
                onClose={() => setUpdateFormModal(false)}
                onSave={onSaveSubmit}
                addressBook={selectedAddressBook}
                setAsDefault={true}
            />
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                size="xs"
                contentClassName="geolocation-address-book-save-modal"
            >
                <FrameAutoPanelHeader
                    toggle={props.onClose}
                    title=""
                />
                <FrameAutoPanelBody className="geolocation-address-book-save-modal_body">
                    <div className="geolocation-address-book-save-modal_icon">
                        <RiErrorWarningLine/>
                    </div>
                    <h3 className="geolocation-address-book-save-modal_title">
						Save current address?
                    </h3>
                    <div className="geolocation-address-book-save-modal_text">
                        <p>
							You may save this current address and set it as a default address if you want to order from
							this location again.
                        </p>
                    </div>
                </FrameAutoPanelBody>

                <FrameAutoPanelFooter>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="normal"
                        onClick={onSaveCurrent}
                        forwardProps={{type: "button"}}
                    >
						Save Current Address
                    </FrameButton>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="gray"
                        size="normal"
                        onClick={onNotSave}
                        forwardProps={{type: "button"}}
                    >
						No, I don't want to save
                    </FrameButton>
                </FrameAutoPanelFooter>
            </FrameOneAutoPanel>
        </>
    );
}

export default GeolocationAddressBookSaveModal;
