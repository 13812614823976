/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetGuildsResponseAllOf,
    GetGuildsResponseAllOfFromJSON,
    GetGuildsResponseAllOfFromJSONTyped,
    GetGuildsResponseAllOfToJSON,
    Guild,
    GuildFromJSON,
    GuildFromJSONTyped,
    GuildToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetGuildsResponse
 */
export interface GetGuildsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Guild>}
     * @memberof GetGuildsResponse
     */
    guilds: Array<Guild>;
}

export function GetGuildsResponseFromJSON(json: any): GetGuildsResponse {
    return GetGuildsResponseFromJSONTyped(json, false);
}

export function GetGuildsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGuildsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'guilds': ((json['guilds'] as Array<any>).map(GuildFromJSON)),
    };
}

export function GetGuildsResponseToJSON(value?: GetGuildsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'guilds': ((value.guilds as Array<any>).map(GuildToJSON)),
    };
}


