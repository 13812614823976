import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useContext, useState} from "react";
import {
    GetMenuResponse,
    MenuCategory,
    MenuItem,
    MenuOrderItem,
    UsersApi,
} from "@devour/client";
import {useParams} from "react-router";
import MenuItemCard from "./MenuItemCard";
import SearchInput from "../../../components/inputs/SearchInput";
import {MdArrowBack} from "react-icons/md";
import {RestaurantContext} from "../context/RestaurantContext";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    setMobileSearchMode: Dispatch<SetStateAction<boolean>>;
    restaurantMenu: GetMenuResponse;
    updateMenuOrderItemManager: (key: MenuOrderItem[]) => void;
    menuOrderItemsManager: { [key: string]: MenuOrderItem };
}

function RestaurantMenusSearch(props: Props): ReactElement {

    const {restaurantId} = useContext(RestaurantContext);
    const {placeId} = useParams<{ placeId: string; }>();
    const {data: restaurant} = useRestaurant(restaurantId, placeId);
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const menuCategoriesRoot = props.restaurantMenu?.menus?.filter((category) => category.isEnabled);
    const [
        searchValue,
        setSearchValue,
    ] = useState("");
    const menuItemsFiltered: Array<MenuItem> = menuCategoriesRoot.map(menuItemsFlattened) // Type Array<Array<MenuItem>>
        .flat(); // Type Array<MenuItem>

    /**
	 * Favorite / unfavorite this restaurant.
	 */
    async function onFavorite(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new UsersApi(getConfig(fullToken)).favoriteBusiness({
                businessId: restaurant.id,
            });
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            await dispatch(updateCurrentUser(userRes));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    /**
	 * Recursive function to loop through categories get menu items matching search query
	 * @param category
	 */
    function menuItemsFlattened(category: MenuCategory): Array<MenuItem> {
        const subItems: Array<MenuItem> = category.menuSubcategories?.filter((subcategory) => subcategory.isEnabled)
            .map(menuItemsFlattened)
            .flat();
        return category.menuItems
            .concat(subItems)
            .filter((item) => filterItem(item, searchValue));
    }

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function clearSearch(): void {
        setSearchValue("");
        props.setMobileSearchMode(false);
    }

    function filterItem(item: MenuItem, search: string): boolean {
        if (!item.isEnabled) {
            return false;
        }
        if (!search) {
            return false;
        }
        return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
            item.description?.toLowerCase()?.includes(search.toLowerCase());
    }

    if (!props.restaurantMenu || !restaurant) {
        return null;
    }

    return (
        <div className="restaurant-menus-search">
            <div className="restaurant-menus-search_title-row">
                <div className="restaurant-menus-search_title-row_back">
                    <button
                        className="reset-button"
                        onClick={clearSearch}
                    >
                        <MdArrowBack/>
                    </button>
                </div>
                <div className="restaurant-menus-search_title-row_title">
                    {restaurant.name}
                </div>
                <div className="restaurant-menus-search_title-row_favorite">
                    {currentUser?.user && !currentUser.user.favorites?.includes(restaurant.id) &&
                    <button
						    className="restaurant-menus-search_title-row_favorite_add"
						    title="Add to saved list"
						    onClick={onFavorite}
                    >
						    <AiOutlineHeart/>
                    </button>
                    }
                    {currentUser?.user && currentUser.user.favorites?.includes(restaurant.id) &&
                    <button
						    className="restaurant-menus-search_title-row_favorite_remove"
						    title="Remove from saved list"
						    onClick={onFavorite}
                    >
						    <AiFillHeart/>
                    </button>
                    }
                </div>
            </div>

            <div className="restaurant-menus-search_info">
                <div className="restaurant-menus-search_info_search">
                    <SearchInput
                        autoFocus={true}
                        placeholder="Search restaurant menu"
                        value={searchValue}
                        onChange={handleSearchChange}
                        maxLength={15}
                        onClear={clearSearch}
                    />
                </div>
            </div>

            <div className="restaurant-menus-search_results">

                {menuItemsFiltered.length > 0 &&
                <>
					    <p className="restaurant-menus-search_results_counter">
					        {menuItemsFiltered.length} results for "{searchValue}"
					    </p>
					    <ul className="restaurant-menus-search_results_list">
					        {menuItemsFiltered.map((menuItem) => <li
                            key={menuItem.id}
                            className="restaurant-menus-search_results_list_item"
                        >
                            <MenuItemCard
                                restaurantMenu={props.restaurantMenu}
                                placeId={placeId}
                                menuItem={menuItem}
                                updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                                menuOrderItemsManager={props.menuOrderItemsManager}
                            />
                        </li>)}
					    </ul>
                </>
                }
                {menuItemsFiltered.length === 0 && searchValue &&
                <p className="restaurant-menus-search_results_empty-message">
						No items on this menu match your current search.
                </p>
                }
            </div>
        </div>
    );
}

export default RestaurantMenusSearch;
