import { TbPlus, TbTrashXFilled } from "react-icons/tb";
import { StepperButton } from "./StepperButton";
import useThemePreference from "@/hooks/useThemePreference";

interface QuantityStepperProps {
    value: number;
    onChange: (value: number) => void;
    step?: number;
    max?: number;
    canIncrement: (value: number) => boolean;
}

export const QuantityStepper = ({value, onChange, step = 1, max, canIncrement}: QuantityStepperProps) => {
    const {isOnDarkMode} = useThemePreference();
    const background = isOnDarkMode ? "#141416" : "#F7F7F8";
    const color = isOnDarkMode ? "#F7F7F8" : "black";

    const disableIncrement = !canIncrement(value + step);

    const onTrash = (e) => {
        e.preventDefault();
        onChange(0);
    };

    const onIncrement = (e) => {
        e.preventDefault();
        if (max && value + step > max) return;
        onChange(value + step);
    };

    return (
        <div className="menu-item-customization-form_group_quantity-stepper_container">
            <div className="menu-item-customization-form_group_quantity-stepper_background" style={{backgroundColor: background,
                zIndex: 1}}></div>
            <StepperButton onClick={onTrash}><TbTrashXFilled/></StepperButton>
            <div
                className="menu-item-customization-form_group_quantity-stepper_value"
                style={{color: color }}
            >{value}</div>
            <StepperButton disabled={disableIncrement} onClick={onIncrement}><TbPlus/></StepperButton>
        </div>
    );
};