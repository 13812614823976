import {ButtonHTMLAttributes, ReactElement} from "react";
import {Guild, GuildsApi} from "@devour/client";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import {
    addError,
    decrementLoading,
    incrementLoading,
} from "@/redux/meta/metaActions";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    guild: Guild;
}

function GuildJoinButton(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { refetch: refetchGuildProgress } = useGetGuildProgress(props.guild?.id, fullToken);

    async function leaveGuild(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new GuildsApi(getConfig()).leaveGuild();
            await refetchGuildProgress();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    return (
        <div className="guild-leave">
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="danger"
                size="large"
                onClick={leaveGuild}
            >
								Leave Current Guild
            </FrameButton>
        </div>
    );
}

export default GuildJoinButton;
