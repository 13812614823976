import {ReactElement} from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";

function GoVipExtendedExperienceBar(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const { isOnDarkMode } = useThemePreference();
    return (
        <div className="go-vip-experience-bar">
            <ProgressBar
                height="15px"
                bgColor={isOnDarkMode
                    ? "linear-gradient(268deg, #F1EDFF 13.64%, #B67BFF 62.98%)"
                    : "linear-gradient(261deg, #C7A6FF -4.9%, #3E00A7 96.73%)"}
                baseBgColor={isOnDarkMode
                    ? "#2B1D52"
                    : "rgba(249, 248, 254, 0.40)"}
                className="go-vip-experience-bar_bar"
                isLabelVisible={false}
                completed={fullToken && accountLevel
                    ? accountLevel.experienceGainedAtThisLevel
                    : 0}
                maxCompleted={fullToken && accountLevel
                    ? accountLevel.experienceGainedAtThisLevel +
                accountLevel.experienceRequiredForNextLevel
                    : 0}
            />
        </div>
    );
}

export default GoVipExtendedExperienceBar;
