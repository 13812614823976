import classNames from "classnames";
import {ReactComponent as NftProfileBadge} from "../svgs/nft-profile-badge.svg";


interface Props {
    size: "sm" | "md" | "lg" | "xl";
    className?: string;
}

export default function NftProfilePictureBadge(props: Props) {
    return (
        <div className={classNames("profile-picture-badge", props.size, props.className)}>
            <NftProfileBadge />
        </div>
    );
}