import {ReactElement} from "react";
import {Guild} from "@devour/client";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GuildMintBadgeButton from "@/components/guilds/GuildMintBadgeButton";
import GuildJoinButton from "@/components/guilds/GuildJoinButton";
import GuildLeaveButton from "@/components/guilds/GuildLeaveButton";
import GuildQuestClaimButton from "@/components/guilds/GuildQuestCard";

interface Props {
    guild: Guild;
}

function GuildCard(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: guildProgress } = useGetGuildProgress(props.guild?.id, fullToken);

    return (
        <div className="guild-card">
            <h3>
                {props.guild.name}
            </h3>
            <p>
                Quests
            </p>
            <ul>
                {guildProgress?.quests.map((quest) =>
                    <li key={quest.id}>
                        <p>
                            <strong>
                                {quest.name}
                            </strong>
                        </p>
                        <p>
                            {quest.description}
                        </p>
                        <p>
                            Type: {quest.rewardType}
                        </p>
                        <p>
                            Current Progress: {quest.userStat?.progress || 0} / {quest.target}
                        </p>
                        <p>
                            Completed? {quest.userStat?.isCompleted ? "Yes" : "No"}
                        </p>
                        <GuildQuestClaimButton quest={quest} />
                    </li>)}
            </ul>

            <GuildMintBadgeButton guild={props.guild} />
            <GuildJoinButton guild={props.guild} />
            <GuildLeaveButton guild={props.guild} />
        </div>
    );
}

export default GuildCard;
