import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useState,
    MouseEvent,
    ButtonHTMLAttributes, useEffect,
} from "react";
import {
    MenuItem,
    MenuOrderItem,
    ErrorType,
} from "@devour/client";
import FrameButton from "../../../components/buttons/FrameButton";
import {RestaurantContext} from "../context/RestaurantContext";
import {
    addError,
    decrementLoading,
    incrementLoading,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IoTrashBin} from "react-icons/io5";
import {FaPlus, FaMinus, FaLock} from "react-icons/fa";
import {isRestaurantAvailable, useRestaurantAvailability} from "@/hooks/useRestaurantAvailability";
import useThemePreference from "@/hooks/useThemePreference";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useRestaurant} from "@/hooks/useRestaurant";
import {IStore} from "@/redux/defaultStore";

interface Props {
    defaultQuickAddItem?: MenuOrderItem;
    placeId: string;
    menuItem: MenuItem;
    setShowCustomizationModal: Dispatch<SetStateAction<boolean>>;
    updateMenuOrderItemManager: (key: MenuOrderItem[]) => void;
    menuOrderItemsManager: { [key: string]: MenuOrderItem };
}

function MenuItemCardAddButton(props: Props): ReactElement {
    const {isOnDarkMode} = useThemePreference();
    const dispatch = useDispatch();
    const {menuOrderId, restaurantId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId, props.placeId);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const [
        quickAdd,
        setQuickAdd,
    ] = useState<boolean>(false);
    const {data: restaurantAvailability} = useRestaurantAvailability(restaurant);
    const availabilityCheckFailed = !isRestaurantAvailable(restaurantAvailability);
    const orderItem = props.menuOrderItemsManager
        ? props.menuOrderItemsManager[Object.keys(props.menuOrderItemsManager).find(k => k.startsWith(props.menuItem.id))]
        : undefined;
    const isMenuOrderUpdating = useSelector((store: IStore) => store.metaStore.isMenuOrderUpdating);

    useEffect(() => {
        if (
            props.menuOrderItemsManager &&
            !props.menuOrderItemsManager[Object.keys(props.menuOrderItemsManager).find(k => k.startsWith(props.menuItem.id))] &&
            quickAdd
        ) {
            setQuickAdd(false);
        }
    }, [props.menuOrderItemsManager]);

    function createNewShoppingCart(quantity: number = 1) {
        const newQuickAddItem: MenuOrderItem = props.defaultQuickAddItem || {
            menuItemId: props.menuItem.id,
            quantity: quantity,
            subtotal: 0, // Subtotal property is required but will be overwritten by backend
            price: props.menuItem.price, // Price property is required but will be overwritten by backend
            taxRate: 0, // Tax rate property is required but will be overwritten by backend
            tax: 0, // Tax property is required but will be overwritten by backend
            name: props.menuItem.name, // Name property is required but will be overwritten by backend
        };
        props.updateMenuOrderItemManager([newQuickAddItem]);
    }

    function addToExistingShoppingCart(quantity: number) {
        dispatch(incrementLoading());
        try {
            const ids = Object.keys(props.menuOrderItemsManager || {}).filter(k => k.startsWith(props.menuItem.id));
            const item = props.menuOrderItemsManager ? props.menuOrderItemsManager[ids[0]] : undefined;
            if (ids.length > 0) { // Item is already in cart
                if (quantity > 0) {
                    // Update quantity of item
                    item.quantity = quantity;
                    props.updateMenuOrderItemManager([item]);
                } else {
                    // Remove this item if new quantity is 0
                    item.quantity = -1;
                    props.updateMenuOrderItemManager([item]);
                }
            } else {
                const newQuickAddItem: MenuOrderItem = props.defaultQuickAddItem || {
                    menuItemId: props.menuItem.id,
                    quantity: 1,
                    subtotal: 0, // Subtotal property is required but will be overwritten by backend
                    price: props.menuItem.price, // Price property is required but will be overwritten by backend
                    taxRate: 0, // Tax rate property is required but will be overwritten by backend
                    tax: 0, // Tax property is required but will be overwritten by backend
                    name: props.menuItem.name, // Name property is required but will be overwritten by backend
                };

                // Update new item to item manager
                props.updateMenuOrderItemManager([newQuickAddItem]);
            }
        } catch (e) {
            props.setShowCustomizationModal(true);
            // Set timeout on the error modal to ensure it starts on top of the customization modal
            setTimeout(async () => {
                dispatch(await addError({
                    type: ErrorType.APP,
                    message: "Some of the modifiers you previously selected are no longer available, please review your modifier selections.",
                }));
            }, 500);
        } finally {
            dispatch(decrementLoading());
        }
    }

    function updateQuantity(quantity: number) {
        if (menuOrder) {
            addToExistingShoppingCart(quantity);
        } else {
            createNewShoppingCart(quantity);
        }
        if (quantity <= 0) {
            setQuickAdd(false);
        }
    }

    function initialOnClick(event: MouseEvent<HTMLButtonElement>): Promise<void> {
        event.stopPropagation();
        if (availabilityCheckFailed) {
            return;
        }

        if (props.defaultQuickAddItem || !props.menuItem.customizations?.length) { // Check if item does not have customization options
            // If item does not exist in cart, automatically add one
            if (!orderItem) {
                if (menuOrder) {
                    addToExistingShoppingCart(1);
                } else {
                    createNewShoppingCart(1);
                }
            }
            setQuickAdd(true);
        } else {
            // Open customization modal
            props.setShowCustomizationModal(true);
        }
    }

    return (
        <div className="menu-item-card-add-button">
            {quickAdd
                ? <div className="menu-item-card-add-button_quick-add">
                    {(orderItem?.quantity) > 0 &&
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={() => updateQuantity((orderItem?.quantity || 0) - 1)}
                            color={isOnDarkMode
                                ? "purple"
                                : "white-drop-shadow"}
                            size="icon"
                            leftIcon={orderItem?.quantity === 1
                                ? IoTrashBin
                                : FaMinus}
                            showSpinnerWithoutGlobalLoading={isMenuOrderUpdating}
                            spinnerLoadingText=""
                            showSpinner={true}
                        />
                    }
                    <div className="menu-item-card-add-button_quick-add_quantity">
                        {orderItem?.quantity || 0} x
                    </div>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        onClick={() => updateQuantity((orderItem?.quantity || 0) + 1)}
                        color={isOnDarkMode
                            ? "purple"
                            : "white-drop-shadow"}
                        size="icon"
                        leftIcon={FaPlus}
                        showSpinnerWithoutGlobalLoading={isMenuOrderUpdating}
                        spinnerLoadingText=""
                        showSpinner={true}
                    />
                </div>
                : orderItem?.quantity
                    ? <div className="menu-item-card-add-button_initial">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={initialOnClick}
                            color={isOnDarkMode
                                ? "purple"
                                : "white-drop-shadow"}
                            size="icon"
                        >
                            {orderItem?.quantity} x
                        </FrameButton>
                    </div>
                    : <div className="menu-item-card-add-button_initial">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={initialOnClick}
                            color={isOnDarkMode
                                ? "purple"
                                : "white-drop-shadow"}
                            size="icon"
                            leftIcon={availabilityCheckFailed
                                ? FaLock
                                : FaPlus}
                            showSpinnerWithoutGlobalLoading={isMenuOrderUpdating}
                            spinnerLoadingText=""
                            showSpinner={true}
                        />
                    </div>
            }
        </div>
    );
}

export default MenuItemCardAddButton;
