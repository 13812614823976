import {useQuery} from "@tanstack/react-query";
import {
    GetGuildProgressResponse,
    GuildsApi,
    Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchGuildProgress(guildId: string): Promise<GetGuildProgressResponse> {
    if (!guildId) {
        return;
    }
    return await new GuildsApi(getConfig()).getGuildProgress({
        id: guildId,
    });
}

function getGuildProgressQuery(guildId: string, fullToken?: Token) {
    return {
        queryKey: [
            "get-guild-progress",
            guildId,
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fetchGuildProgress(guildId),
        staleTime: 60000,
        enabled: Boolean(guildId),
    } as const;
}

export function useGetGuildProgress(guildId: string, fullToken?: Token) {
    return useQuery(getGuildProgressQuery(guildId, fullToken));
}
