import {useQuery} from "@tanstack/react-query";
import {
    GetGuildsResponse,
    GuildsApi,
} from "@devour/client";

async function fetchGuilds(offset: number, limit: number, search: string, gameIds: string[]): Promise<GetGuildsResponse> {
    return await new GuildsApi().getGuilds({
        offset: offset,
        limit: limit,
        search: search,
        gameIds: gameIds,
    });
}

function getGuildsQuery(offset: number, limit: number, search: string, gameIds: string[]) {
    return {
        queryKey: [
            "get-guilds",
            offset,
            limit,
            search,
            gameIds?.join("-"),
        ],
        queryFn: () => fetchGuilds(offset, limit, search, gameIds),
        staleTime: 60000,
        enabled: Boolean(gameIds.length),
    } as const;
}

export function useGetGuilds(offset?: number, limit?: number, search?: string, gameIds?: string[]) {
    return useQuery(getGuildsQuery(offset, limit, search, gameIds?.filter(Boolean)));
}
