import {ButtonHTMLAttributes, ReactElement} from "react";
import {GuildQuest, GuildsApi} from "@devour/client";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    quest: GuildQuest;
}

function GuildQuestClaimButton(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: guildProgress, refetch: refetchGuildProgress } = useGetGuildProgress(props.quest?.guild, fullToken);
    const hasClaimedAllRewards = props.quest?.userStat?.isClaimed;
    const milestonesReached = props.quest?.milestones?.filter((m) => props.quest?.userStat?.progress >= m.target);
    const unclaimedQuests = props.quest?.milestones?.length
        ? milestonesReached.length - props.quest?.userStat?.claimLogs.length
        : props.quest?.userStat?.isCompleted && !hasClaimedAllRewards
            ? 1
            : 0;

    async function claimQuestReward(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new GuildsApi(getConfig()).claimQuest({
                id: props.quest?.id,
            });
            await refetchGuildProgress();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    if (!guildProgress?.isUserInGuild || !props.quest?.rewardAmount || hasClaimedAllRewards) {
        return null;
    }

    return (
        <div className="guild-mint-badge">
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="narrow"
                onClick={claimQuestReward}
                forwardProps={{
                    disabled: !unclaimedQuests,
                }}
                showSpinner={true}
            >
                Claim {unclaimedQuests} Reward
            </FrameButton>
        </div>
    );
}

export default GuildQuestClaimButton;
