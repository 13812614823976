/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWEventType,
    OWEventTypeFromJSON,
    OWEventTypeFromJSONTyped,
    OWEventTypeToJSON,
    OWEventTypeResponseAllOf,
    OWEventTypeResponseAllOfFromJSON,
    OWEventTypeResponseAllOfFromJSONTyped,
    OWEventTypeResponseAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWEventTypeResponse
 */
export interface OWEventTypeResponse extends OWEventType {
    /**
     * id of the event type
     * @type {string}
     * @memberof OWEventTypeResponse
     */
    id: string;
    /**
     * Timestamp when the event type was created
     * @type {number}
     * @memberof OWEventTypeResponse
     */
    createdAt: number;
    /**
     * Timestamp when the event type was last updated
     * @type {number}
     * @memberof OWEventTypeResponse
     */
    updatedAt: number;
}

export function OWEventTypeResponseFromJSON(json: any): OWEventTypeResponse {
    return OWEventTypeResponseFromJSONTyped(json, false);
}

export function OWEventTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWEventTypeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...OWEventTypeFromJSONTyped(json, ignoreDiscriminator),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function OWEventTypeResponseToJSON(value?: OWEventTypeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...OWEventTypeToJSON(value),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


